import React, { useContext, useEffect, useState } from "react";
import defaultImg from "./../../assets/images/defaultImg.jpg";
import phone from "./../../assets/images/phone.svg";
import message from "./../../assets/images/message.svg";
import { Link, useParams } from "react-router-dom";
import useApiAxios from "../../api/useApiAxios";
import moment from "moment";
import { Context } from "../../context/Context";
import ImageComponent from "../media/ImageComponent";
import smile from "./../../assets/images/smile.svg";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const EmployeeDetails = () => {
  const { id } = useParams();
  const {
    getPatientById,
    getUserPainExportDataApi,
    getUserSatisfactionExportDataApi,
    getUserActivityExportDataApi,
  } = useApiAxios();
  const { setShowEmailData, setShowEmail } = useContext(Context);
  const [data, setData] = useState({});
  const [satisfactionChartOption, setSatisfactionChartOption] = useState(null);
  const [ChartOptionForActivityAndPain, setChartOptionForActivityAndPain] =
    useState(null);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [satisfactionData, setSatisfactionData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedOption, setSelectedOption] = useState("");

  const fun = async () => {
    const res = await getPatientById(id);

    const satisfactionPain1 = [];
    for (
      let index = 0;
      index < res.data.data.customerSatisfactions.length;
      index++
    ) {
      const element = res.data.data.customerSatisfactions[index];
      if (element?.customerSatisfaction) {
        satisfactionPain1.push({
          x: new Date(element.createdAt),
          y: Number(element.customerSatisfaction),
        });
      }
    }
    setSatisfactionChartOption({
      chart: {
        type: "line",
        Height: "300",
      },
      title: {
        text: "Satisfaction Scale",
      },
      xAxis: {
        type: "category",
        labels: {
          formatter: function () {
            const days = [
              "Sunday",
              "Monday",
              "Tuesday",
              "Wednesday",
              "Thursday",
              "Friday",
              "Saturday",
            ];
        
            const date = new Date(satisfactionPain1[this.value].x);
            const weekday = days[date.getDay()];
            return weekday;
          },
        },
      },
      yAxis: {
        title: {
          text: "Percentage",
        },
        max: 100, // Fix the y-axis maximum to 100%
        labels: {
          formatter: function () {
            return this.value + "%";
          },
        },
      },
      series: [
        {
          name: "Value ",
          data: satisfactionPain1.map((item) => [item.x, Math.trunc(item.y)]),
          color: "#FD707B",
        },
      ],
    });

    setData(res.data.data);
    const pain = [];

    const activity = [];
    const groupedByDate = res.data.data.painLabels.reduce((acc, curr) => {
      const date = curr.createdAt.split("T")[0];
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(curr.painLabel);
      return acc;
    }, {});

    // Step 2: Calculate the average pain level for each date
    const averagePainLevels = Object.entries(groupedByDate).map(
      ([date, painLevels]) => {
        const total = painLevels.reduce((sum, painLabel) => sum + painLabel, 0);
        const average = total / painLevels.length;
        return {
          date,
          averagePainLevel: average,
        };
      }
    );

    for (let index = 0; index < averagePainLevels.length; index++) {
      const element = averagePainLevels[index];
      if (element?.averagePainLevel) {
        pain.push({
          x: new Date(element.date),
          y: Number(element.averagePainLevel.toFixed(2)),
        });
      }
    }

    for (let index = 0; index < res.data.data.avgActivity.length; index++) {
      const element = res.data.data.avgActivity[index];
      if (element?.avgActivityLabel) {
        activity.push({
          x: new Date(element.createdAt),
          y: Number(element.avgActivityLabel),
        });
      }
    }

    const lastDataPoint = satisfactionPain1[satisfactionPain1.length - 1];
    if (lastDataPoint) {
      setSatisfactionData({
        percentage: Math.trunc(lastDataPoint.y),
      });
    }
    setIsLoading(false);
    // Set chart options for combined data
    setChartOptionForActivityAndPain({
      chart: {
        type: "line",
        Height: "300",
      },
      title: {
        text: "Pain and Function Scale",
      },
      xAxis: [
        {
          type: "category",
          categories: pain.map((item) => moment(item.x).format("MM/DD/YYYY")),
          title: {
            text: "Date",
          },
        },
        {
          type: "category",
          categories: activity.map((item) =>
            moment(item.x).format("MM/DD/YYYY")
          ),

          opposite: true,
        },
      ],
      yAxis: {
        title: {
          text: "Value",
        },
      },
      series: [
        {
          name: "Average Pain level",
          data: pain.map((item) => item.y),
          color: "#036E49",
        },
        {
          name: "Average Functional level",
          data: activity.map((item) => item.y),
          color: "#FD707B",
        },
      ],
    });
    // Update data state
    setData(res.data.data);
    setIsLoading(false);
  };
  useEffect(() => {
    fun();
  }, [id]);

  const handleUserPainExportData = async () => {
    try {
      const response = await getUserPainExportDataApi(id);
      const url = window.URL.createObjectURL(
        new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "userPainLevels.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error exporting data:", error);
    }
  };

  const handleUserSatisfactionExportData = async () => {
    try {
      const response = await getUserSatisfactionExportDataApi(id);
      const url = window.URL.createObjectURL(
        new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "userSatisfactionLevels.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error exporting data:", error);
    }
  };

  const handleUserActivityExportData = async () => {
    try {
      const response = await getUserActivityExportDataApi(id);
      const url = window.URL.createObjectURL(
        new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "userActivity.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error exporting data:", error);
    }
  };

  const handleDropdownChange = (e) => {
    setIsDropdownVisible(false); // Hide dropdown after selection
    if (e === "Pain Level") {
      handleUserPainExportData();
    } else if (e === "Functional Level") {
      handleUserActivityExportData();
    }
  };
  const getPercentage = (item) => {
    const completedVideos = item.userVideos.filter(
      (item) => item.completed
    ).length;
    const percentage = (completedVideos / item.userVideos.length) * 100;
    return percentage;
  };

  return (
    <div>
      <div className="row">
        <div className="col-xl-5">
          <nav aria-label="breadcrumb" className="theme-breadcrumb">
            <ol className="breadcrumb mb-0 ps-0 pt-1 pb-0">
              <li className="breadcrumb-item">
                <Link
                  to={
                    data.type === "physicalTherapist"
                      ? `/physical-therapists/patient/${data?.physicalTherapist?._id}`
                      : `/singlecompany/${data?.company?._id}`
                  }
                >
                  {data?.type === "physicalTherapist"
                    ? "Patient/"
                    : "Employee/"}
                </Link>
              </li>
              <li className="page-title">
                {data?.type === "physicalTherapist"
                  ? "Patient_Details"
                  : "Employee_Details"}
              </li>
            </ol>
          </nav>
          <h1 className="page-title">
            {" "}
            {data.type === "physicalTherapist" ? "Patient" : "Employee"} Details
          </h1>
        </div>
      </div>
      {/* Companies */}

      <div className="row">
        <div className="col-xl-4 mb-4 align-items-stretch">
          <div className="card customer-sf h-100">
            <div className="card-body p-3">
              <div className="row">
                <div className="col-12 mb-5 pb-5">
                  <div className="coplany-info">
                    <div className="float-start c-logo-manage">
                      <div className="company-logo d-flex justify-content-center align-items-center float-start">
                        <ImageComponent
                          src={
                            data?.profileImage
                              ? `${data?.profileImage}`
                              : defaultImg
                          }
                        />
                      </div>
                    </div>
                    <div className="company-namearea float-start">
                      <h4 className="c-title">{data?.name}</h4>
                      <h6 className="c-subtitle"> </h6>

                      <div className="col-sm-4 mb-2 mb-sm-0">
                        <h6 className="fw-bold d-inline-block text-truncate">
                          {data?.lastSeen
                            ? moment(data?.lastSeen).format("MM/DD/YYYY")
                            : "Null"}
                        </h6>
                      </div>

                      <div className="action-icon">
                        <div className="action-icon">
                          <Link
                            to={`tel:${data?.phone?.substring(
                              0,
                              3
                            )}-${data?.phone?.substring(3)}`}
                            className="ps-1 pe-2 opc8"
                          >
                            <img src={phone} alt="" />
                          </Link>

                          <Link
                            onClick={() => {
                              setShowEmail(true);
                              setShowEmailData([
                                {
                                  email: data.email,
                                  name: data.name,
                                  selected: true,
                                },
                              ]);
                            }}
                            className="px-2 opc8"
                          >
                            <img src={message} alt="" />
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="company-satisfaction-container">
                      {isLoading ? (
                        <p>Loading...</p> // Display a loading indicator
                      ) : satisfactionData ? (
                        <div className="company-satisfaction float-end">
                          <p className="title-cs">
                            Customer <br /> Satisfaction
                          </p>
                          <p className="title-pr d-inline-flex align-items-center mb-1">
                            <img src={smile} alt="Smile" className="me-1" />
                            {satisfactionData.percentage
                              ? satisfactionData.percentage + "%"
                              : "N/A"}
                          </p>
                        </div>
                      ) : (
                        <div className="company-satisfaction float-end">
                          <p className="title-cs">
                            Customer <br /> Satisfaction
                          </p>
                          <p className="title-pr d-inline-flex align-items-center mb-1">
                            <img src={smile} alt="Smile" className="me-1" />
                            N/A
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row compamy-numbers">
                <div className="col-sm-4 mb-2 mb-sm-0">
                  <h6 className="fw-bold">
                    {" "}
                    {data?.dateOfBirth
                      ? moment(data?.dateOfBirth).format("MM/DD/YYYY")
                      : "Not provide"}
                  </h6>
                  <p>Date Of Birth</p>
                </div>
                <div className="col-sm-4 mb-2 mb-sm-0 px-0 bdr-color border-end border-start">
                  <h6 className="fw-bold">
                    {" "}
                    {moment(data.createdAt).format("MM/DD/YYYY")}
                  </h6>
                  <p>Start Date</p>
                </div>
                <div className="col-sm-4 mb-2 mb-sm-0">
                  <h6 className="fw-bold">
                    {data?.gender ? data?.gender : "Not Provide"}
                  </h6>
                  <p>Gender</p>
                </div>
              </div>
              <hr style={{ borderBottom: "1px solid #E9EDF7" }} />
              <div className="row compamy-numbers">
                <div className="col-sm-4 mb-2 mb-sm-0">
                  <h6 className="fw-bold">
                    {data?.type === "physicalTherapist"
                      ? data.subscription
                        ? "Yes "
                        : "No"
                      : "Yes"}
                  </h6>
                  <p>Subscription</p>
                </div>
                <div className="col-sm-4 mb-2 mb-sm-0 bdr-color border-end border-start">
                  <h6 className="fw-bold">
                    {data?.currentUserAnswer?.goal
                      ? data?.currentUserAnswer?.goal
                      : "null"}
                  </h6>
                  <p>Goal</p>
                </div>
                <div className="col-sm-4 mb-2 mb-sm-0">
                  <h6 className="fw-bold">
                    {data?.currentUserAnswer?.painLabel
                      ? data?.currentUserAnswer?.painLabel
                      : "null"}
                  </h6>
                  <p>Current Pain Level</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /Col */}
        <div className="col-xl-4 mb-4 align-items-stretch">
          <div className="card h-100">
            <div className="card-body py-2 px-0">
              <div id="pain_activity_functional">
                {/* Export Button */}
                <div className="bulk-action d-flex justify-content-end me-2">
                  <button
                    className="btn btn-sm btn-success"
                    onClick={handleUserSatisfactionExportData}
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    data-bs-auto-close="outside"
                    type="button"
                  >
                    Export
                  </button>
                </div>
                {/* Selection Dropdown */}

                {satisfactionChartOption ? (
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={satisfactionChartOption}
                  />
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* /Col */}
        <div className="col-xl-4 mb-4 align-items-stretch">
          <div className="card h-100">
            <div className="card-body py-2 px-0">
              <div id="pain_activity_functional">
                <div className="bulk-action d-flex justify-content-end me-2">
                  <button
                    className="btn btn-sm btn-success"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    data-bs-auto-close="outside"
                    type="button"
                  >
                    Export
                  </button>

                  <ul className="dropdown-menu dropdown-menu-end drop-tow mt-3 w-25">
                    <li>
                      <button
                        className="dropdown-item "
                        type="button"
                        onClick={() => handleDropdownChange("Pain Level")}
                      >
                        Avg Pain Level
                      </button>
                    </li>
                    <li>
                      <button
                        className="dropdown-item"
                        type="button"
                        onClick={() => handleDropdownChange("Functional Level")}
                      >
                        Avg Functional Level
                      </button>
                    </li>
                  </ul>
                </div>
                {/* Selection Dropdown */}
                {isDropdownVisible && (
                  <div className="form-group mt-2 dropdown-container">
                    <select
                      id="dataType"
                      className="form-control"
                      value={selectedOption}
                      onChange={handleDropdownChange}
                    >
                      <option value="" disabled>
                        Select Data Type
                      </option>
                      <option value="Pain Level">Pain Level</option>
                      <option value="Functional Level">
                        Avg Functional Level
                      </option>
                    </select>
                  </div>
                )}
                {ChartOptionForActivityAndPain ? (
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={ChartOptionForActivityAndPain}
                  />
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* /Col */}
      </div>
      {/* /Row */}
      <div className="row">
        <div className="col-xxl-12 col-xl-12 mb-3 order-4 order-xxl-3">
          <div className="card customer-sf h-100">
            <div className="p-30 pb-0">
              <h4 className="customer-sf-title d-flex justify-content-between align-items-center">
                Progress
              </h4>
            </div>
            <div className="pb-4 companies-table">
              <div className="table-responsive mt-2">
                <table className="table table-borderless mb-0">
                  <thead>
                    <tr>
                      <th>Module Name</th>
                      <th>Progress</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.currentUserAnswer?.userVideoModule.map(
                      (item, index) => (
                        <tr>
                          <td className="" key={index}>
                            {item?.videoModule?.title}
                          </td>

                          <td className="">
                            <div className="progress progress-bg h-4">
                              <div
                                className="progress-bar progress-blue"
                                role="progressbar"
                                style={{
                                  width: `${getPercentage(item)}%`,
                                }}
                                aria-valuemin={0}
                                aria-valuemax={100}
                              ></div>
                            </div>
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeDetails;
