import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import useApiAxios from "../../api/useApiAxios";
import * as Yup from "yup";
import { Link, useNavigate, useParams } from "react-router-dom";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import AddOptionModal from "./AddOptionModal";

const AddQuestion = () => {
  const { habId } = useParams();
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    setNextVideo(null);
    setNextQuestion(null);
    setAddedVideoModule(null);
  };

  const {
    createQuestionApi,
    getAllTagsApi,
    getQuestionByHabIdApi,
    getQuestionVideoModuleApi,
    getQuestionVideoApi,
  } = useApiAxios(); // Destructure the createCompany function from the hook
  const [videos, setVideos] = useState([]);

  const [nextVideo, setNextVideo] = useState(null);
  const [nextQuestion, setNextQuestion] = useState(null);
  const [addedVideoModule, setAddedVideoModule] = useState(null);

  const [videoModule, setVideoModule] = useState([]);
  const [question, setQuestion] = useState([]);
  const [optionAction, setOptionAction] = useState([]);
  const initialValues = {
    title: "",
    description: "",
    firstQuestion: false,
    questionId: "",
  };

 
  const createOptions = async (data) => {
    setOptionAction([
      ...optionAction,
      {
        title: data.title,
        finished: data.finished,
        nextVideo,
        nextQuestion,
        addedVideoModule,
      },
    ]);
  };
 
  const [tags, setTags] = useState([]);
  const getTags = async () => {
    const { data, error } = await getAllTagsApi();
    if (!error) {
      const dataArray = [];
      for (let index = 0; index < data.data.length; index++) {
        const element = data.data[index];
        dataArray.push({ value: element._id, label: element.name });
      }
      setTags(dataArray);
    }
  };
  const getVideo = async () => {
    const { data, error } = await getQuestionVideoApi();
    if (!error) {
      const dataArray = [];
      for (let index = 0; index < data.data.length; index++) {
        const element = data.data[index];
        dataArray.push({ value: element._id, label: element.title });
      }
      setVideos(dataArray);
    }
  };
  const getVideoModule = async () => {
    const { data, error } = await getQuestionVideoModuleApi();

    if (!error) {
      const dataArray = [];
      for (let index = 0; index < data.data.length; index++) {
        const element = data.data[index];
        dataArray.push({ value: element._id, label: element.title });
      }
      setVideoModule(dataArray);
    }
  };
  const getQuestions = async () => {
    const { data, error } = await getQuestionByHabIdApi(habId);
    if (!error) {
      const dataArray = [];
      for (let index = 0; index < data.data.length; index++) {
        const element = data.data[index];
        dataArray.push({ value: element._id, label: element.questionId });
      }
      setQuestion(dataArray);
    }
  };
  useEffect(() => {
    getTags();
    getVideo();
    getVideoModule();
    getQuestions();
  }, []);

  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .required("Name is required")
      .max(50, "Name must be at most 50 characters"),

  });


  const onSubmit = async (values, { setSubmitting }) => {
    const { error } = await createQuestionApi(
      { ...values, optionAction },
      habId
    );
    setSubmitting(false);
    navigate(`/question/${habId}`);
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });
  return (
    <div className="container-fluid">
      <div className="row d-flex ">
        <div className="col-sm-5">
          <nav aria-label="breadcrumb" className="theme-breadcrumb">
            <ol className="breadcrumb mb-0 ps-0 pt-1 pb-0">
              <li className="breadcrumb-item">
                <Link to={`/question/${habId}`}>Question</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Add Question
              </li>
            </ol>
          </nav>
          <h1 className="page-title">Add New Question</h1>
        </div>
      </div>
      <div className="row align-items-stretch">
        <div className="col-xxl-12 mb-3 order-1 order-xxl-1">
          <div className="card customer-sf h-100">
            <form onSubmit={formik.handleSubmit}>
              <div className="p-30">
                <div className="row mt-4">
                  <div className="col-md-6 col-xl-6">
                    <div className="row mb-3 form-fieldc">
                      <label className="col-sm-4 col-form-label">Title</label>
                      <div className="col-sm-8">
                        <input
                          type="text"
                          className={`form-control ${
                            formik.errors.title && formik.touched.title
                              ? "is-invalid"
                              : ""
                          }`}
                          id="title"
                          name="title"
                          value={formik.values.title}
                          onChange={formik.handleChange}
                         
                        />
                        {formik.errors.title && formik.touched.title && (
                          <div className="invalid-feedback">
                            {formik.errors.title}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 mb-4">
                    <label className="form-label mt-2">
                      Mark as First Question
                    </label>
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="flexSwitchCheckChecked"
                        defaultChecked=""
                        checked={formik.values.firstQuestion}
                        onChange={(e) =>
                          formik.setFieldValue(
                            "firstQuestion",
                            e.target.checked
                          )
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexSwitchCheckChecked"
                      >
                        Turn on to Mark as First Question
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6 col-xl-6">
                    <div className="row mb-3 form-field">
                      <label className="col-sm-4 col-form-label">
                        Question Id
                      </label>
                      <div className="col-sm-8">
                        <input
                          type="text"
                          className={`form-control ${
                            formik.errors.questionId &&
                            formik.touched.questionId
                              ? "is-invalid"
                              : ""
                          }`}
                          id="questionId"
                          name="questionId"
                          value={formik.values.questionId}
                          onChange={formik.handleChange}
                          maxLength={50}
                        />
                        {formik.errors.questionId &&
                          formik.touched.questionId && (
                            <div className="invalid-feedback">
                              {formik.errors.questionId}
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 col-xl-12">
                    <div className="row mb-3 form-fieldc">
                      <label className="col-sm-2 col-form-label">
                        Description
                      </label>
                      <div className="col-sm-10">
                        <SunEditor
                          setContents={formik.values.description}
                          onChange={(editorText) =>
                            formik.setFieldValue("description", editorText)
                          }
                          setOptions={{
                            buttonList: [
                              [
                                "bold",
                                "underline",
                                "italic",
                                "strike",
                                "subscript",
                                "superscript",
                              ],
                              ["undo", "redo"],
                              ["font", "fontSize"],
                              ["fontColor", "hiliteColor"],
                              ["align", "list", "lineHeight"],

                              ["table", "horizontalRule", "link"],
                            ],
                            defaultTag: "div",
                            minHeight: "100px",
                            showPathLabel: false,
                            
                          }}
                        />

                        {formik.errors.description &&
                          formik.touched.description && (
                            <div className="invalid-feedback">
                              {formik.errors.description}
                            </div>
                          )}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 col-xl-12">
                    <div>
                      <p className="breadcrumb-item">Options</p>
                      <Link
                        onClick={() => setShow(true)}
                        className="btn addsubmit-btn green-btn  "
                      >
                        Add Options
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="mt-2 table-responsive">
                  <table
                    id="example"
                    className="table display table-edits"
                    cellSpacing={0}
                    width="100%"
                  >
                    <thead>
                      <tr>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example"
                        >
                          <span className="nochecked">
                            <div className="form-check">
                              <label
                                className="form-check-label text-truncate"
                                htmlFor="remember1"
                              >
                                Title
                              </label>
                            </div>
                          </span>
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example"
                        >
                          <span className="nochecked">
                            <div className="form-check">
                              <label
                                className="form-check-label text-truncate"
                                htmlFor="remember1"
                              >
                                Finished
                              </label>
                            </div>
                          </span>
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example"
                        >
                          <span className="nochecked">
                            <div className="form-check">
                              <label
                                className="form-check-label text-truncate"
                                htmlFor="remember1"
                              >
                                Next Question Id
                              </label>
                            </div>
                          </span>
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example"
                        >
                          <span className="nochecked">
                            <div className="form-check">
                              <label
                                className="form-check-label text-truncate"
                                htmlFor="remember1"
                              >
                                Next Video
                              </label>
                            </div>
                          </span>
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example"
                        >
                          <span className="nochecked">
                            <div className="form-check">
                              <label
                                className="form-check-label text-truncate"
                                htmlFor="remember1"
                              >
                                Added Video Module
                              </label>
                            </div>
                          </span>
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example"
                        >
                          <span className="nochecked">
                            <div className="form-check">
                              <label
                                className="form-check-label text-truncate"
                                htmlFor="remember1"
                              >
                                Delete
                              </label>
                            </div>
                          </span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {optionAction.map((companyData, index) => (
                        <tr key={index}>
                          <td>
                            <span className="form-check d-flex align-items-center ">
                              {companyData?.title}
                            </span>
                          </td>
                          <td>
                            <span className="form-check d-flex align-items-center ">
                              {companyData?.finished.toString()}
                            </span>
                          </td>
                          <td>
                            <span className="form-check d-flex align-items-center ">
                              {companyData?.nextQuestion?.label}
                            </span>
                          </td>
                          <td>
                            <span className="form-check d-flex align-items-center ">
                              {companyData?.nextVideo?.label}
                            </span>
                          </td>
                          <td>
                            <span className="form-check d-flex align-items-center ">
                              {companyData.addedVideoModule
                                ?.map((option) => option.label)
                                .join(", ")}
                            </span>
                          </td>
                          <td>
                            <span className="form-check d-flex align-items-center ">
                              <button
                                onClick={() =>
                                  setOptionAction(
                                    optionAction.filter(
                                      (a) => a.title !== companyData.title
                                    )
                                  )
                                }
                              >
                                Delete
                              </button>
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                <div className="row mt-4">
                  <div className="col-md-6 col-lg-12 col-xl-6">
                    <div className="row mb-3 form-fieldc">
                      <label className="col-sm-4 col-form-label" />
                      <div className="col-sm-8">
                        <button
                          className="btn addsubmit-btn green-btn me-3 mb-3"
                          type="submit"
                          disabled={formik.isSubmitting}
                        >
                          Add Question
                        </button>

                        <Link
                          to={`/question/${habId}`}
                          className="btn addsubmit-btn grey-btn  mb-3"
                        >
                          Cancel
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <AddOptionModal
        show={show}
        handleClose={handleClose}
        videos={videos}
        setNextVideo={setNextVideo}
        videoModule={videoModule}
        question={question}
        setNextQuestion={setNextQuestion}
        setAddedVideoModule={setAddedVideoModule}
        createOptions={createOptions}
      />
    </div>
  );
};

export default AddQuestion;
