import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import useApiAxios from "../../api/useApiAxios";
import * as Yup from "yup";
import { useFormik } from "formik";
import Select from "react-select";
import usStates from "./usStates.json"; // Import the JSON file

const EditCompany = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const { id } = useParams(); // Get the ID from the URL

  const { getCompany, updateCompanyApi, getAllTagsApi } = useApiAxios();
  const [tags, setTags] = useState(null);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedTagsSelected, setSelectedTagsSelected] = useState([]);
  const [showOtherInput, setShowOtherInput] = useState(false);
  const [otherIndustry, setOtherIndustry] = useState("");

  const [showOtherInputState, setShowOtherInputState] = useState(false);
  const [otherState, setOtherState] = useState("");

  const handleIndustryChange = (e) => {
    const selectedIndustry = e.target.value;
    if (selectedIndustry === "others") {
      setShowOtherInput(true);
    } else {
      setShowOtherInput(false);
      setOtherIndustry("");
    }
    
  };

  const handleStateChange = (e) => {
    const selectedState = e.target.value;
    if (selectedState === "others") {
      setShowOtherInputState(true);
    } else {
      setShowOtherInputState(false);
      setOtherState("");
    }
    
  };

  const industryOptions = [
    { value: "tech", label: "Technology" },
    { value: "finance", label: "Finance" },
    { value: "healthcare", label: "Healthcare" },
    { value: "others", label: "others" },
  ];

  const stateOptions = usStates; // Use the imported JSON data

  const employeeOptions = [
    { value: "1 to 50", label: "1 to 50" },
    { value: "51 to 100", label: "51 to 100" },
    { value: "101 to 500", label: "101 to 500" },
    { value: "500+", label: "500+" },
  ];

  useEffect(() => {
    const fetchCompanyData = async () => {
      const response = await getAllTagsApi();
      const dataArray = [];
      if (!response.error) {
        for (let index = 0; index < response.data.data.length; index++) {
          const element = response.data.data[index];
          dataArray.push({ value: element._id, label: element.name });
        }
      }
      setTags(dataArray);

      const { data, error } = await getCompany(id);
      if (!error) {
        const industryCondition = industryOptions.some(
          (item) => item.value === data.data?.industry
        );
        formik.setValues({
          ...data?.data,
          industry: industryCondition ? data?.data?.industry : "others",
        }); // Populate form values
        if (!industryCondition) {
          setShowOtherInput(true);
          setOtherIndustry(data?.data?.industry);
        }
        const stateCondition = stateOptions.some(
          (item) => item.value === data.data?.state
        );
        formik.setValues({
          ...data?.data,
          state: stateCondition ? data?.data?.state : "others",
        }); // Populate form values
        if (!stateCondition) {
          setShowOtherInputState(true);
          setOtherState(data?.data?.state);
        }
      } else {
        setError(data.message);
      }
      setSelectedTags(data.data.tags);
      const dataToSet = [];
      for (let index = 0; index < data.data.tags.length; index++) {
        const element = data.data.tags[index];
        for (let index2 = 0; index2 < dataArray.length; index2++) {
          const element2 = dataArray[index2];
          if (element === element2.value) {
            dataToSet.push(element2);
          }
        }
      }
      setSelectedTagsSelected(dataToSet);
      setIsLoading(false);
    };

    fetchCompanyData();
  }, [id]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    country: Yup.string().required("Address is required"),
    address: Yup.string().required("Address is required"),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required"),
    zip: Yup.string()
      .required("Zip code is required")
      .max(6, "zip must be at most 6 characters"),
    phone: Yup.string().required("Phone is required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      industry: "",
      country: "",
      address: "",
      city: "",
      state: "",
      zip: "",
      phone: "",
      email: "",
      employees: "",
      selectPlan: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const response = await updateCompanyApi(id, {
          ...values,
          tags: selectedTags,
          industry:
            values.industry === "others" ? otherIndustry : values.industry,
          state: values.state === "others" ? otherState : values.state,
        });

        if (!response.error) {
          setOtherIndustry("");
          setOtherState("");
        } else {
          setError(response.data.message);
        }
      } catch (error) {
        setError("An error occurred while updating company data.");
      }
    },
  });


  return (
    <div className="page-content">
      <div className="container-fluid">
        <div className="row d-flex ">
          <div className="col-sm-5">
            <nav aria-label="breadcrumb" className="theme-breadcrumb">
              <ol className="breadcrumb mb-0 ps-0 pt-1 pb-0">
                <li className="breadcrumb-item">
                  <Link to="/company">Companies </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Update Company
                </li>
              </ol>
            </nav>
            <h1 className="page-title">Update Company</h1>
          </div>
          <div className="col-sm-7 mb-3 mb-sm-0 d-flex justify-content-start justify-content-sm-end align-items-center">
            <a href="#!" className="btn company-btn">
              <i className="icon-19" /> Update Company
            </a>
          </div>
        </div>
        {/* Companies */}
        <div className="row align-items-stretch">
          {/* Companies */}
          <div className="col-xxl-12 mb-3 order-1 order-xxl-1">
            <div className="card customer-sf h-100">
              <form onSubmit={formik.handleSubmit}>
                <div className="p-30">
                  <div className="row mt-4">
                    <div className="col-md-6 col-xl-6">
                      <div className="row mb-3 form-fieldc">
                        <label className="col-sm-4 col-form-label">Name</label>
                        <div className="col-sm-8">
                          <input
                            type="text"
                            className="form-control"
                            id="name"
                            name="name"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            maxLength={50}
                          />
                          {formik.errors.name ? (
                            <div className="error">{formik.errors.name}</div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row ">
                    <div className="col-md-6 col-xl-6">
                      <div className="row mb-3 form-fieldc">
                        <label className="col-sm-4 col-form-label">
                          Industry
                        </label>
                        <div className="col-sm-8">
                          <select
                            className="form-select"
                            id="industry"
                            name="industry"
                            value={formik.values.industry}
                            onChange={(e) => {
                              formik.handleChange(e);
                              handleIndustryChange(e); // Call custom handler after formik's handleChange
                            }}
                            onBlur={formik.handleBlur}
                          >
                            <option value="">Choose...</option>
                            {industryOptions.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                          {formik.errors.industry ? (
                            <div className="error">
                              {formik.errors.industry}
                            </div>
                          ) : null}

                          {showOtherInput && (
                            <input
                              type="text"
                              className="form-control mt-2"
                              id="otherIndustry"
                              name="otherIndustry"
                              placeholder="Enter other industry"
                              value={otherIndustry}
                              onChange={(e) => setOtherIndustry(e.target.value)}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-xl-6">
                    <div className="row mb-3 form-fieldc">
                      <label className="col-sm-4 col-form-label">Country</label>
                      <div className="col-sm-8">
                        <input
                          type="text"
                          className={`form-control ${
                            formik.errors.country && formik.touched.country
                              ? "is-invalid"
                              : ""
                          }`}
                          id="country"
                          name="country"
                          value={formik.values.country}
                          onChange={formik.handleChange}
                        />
                        {formik.errors.country && formik.touched.country && (
                          <div className="invalid-feedback">
                            {formik.errors.country}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-xl-6">
                    <div className="row mb-3 form-fieldc">
                      <label className="col-sm-4 col-form-label">Address</label>
                      <div className="col-sm-8">
                        <input
                          type="text"
                          className={`form-control ${
                            formik.errors.address && formik.touched.address
                              ? "is-invalid"
                              : ""
                          }`}
                          id="address"
                          name="address"
                          value={formik.values.address}
                          onChange={formik.handleChange}
                          maxLength={256}
                        />
                        {formik.errors.address && formik.touched.address && (
                          <div className="invalid-feedback">
                            {formik.errors.address}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row ">
                    <div className="col-md-6 col-xl-6">
                      <div className="row mb-3 form-fieldc">
                        <label className="col-sm-4 col-form-label">City</label>
                        <div className="col-sm-8">
                          <input
                            type="text"
                            className="form-control"
                            id="city"
                            name="city"
                            value={formik.values.city}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            maxLength={256}
                          />
                          {formik.errors.city ? (
                            <div className="error">{formik.errors.city}</div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-5 col-md-6">
                      <div className="row">
                        <div className="col-md-7">
                          <div className="row mb-3 form-fieldc">
                            <label className="col-md-5 col-sm-4 col-form-label text-nowrap ps-3 ps-lg-5">
                              State
                            </label>
                            <div className="col-md-7 col-sm-8">
                              <select
                                className="form-select"
                                id="state"
                                name="state"
                                value={formik.values.state}
                                onChange={(e) => {
                                  formik.handleChange(e);
                                  handleStateChange(e); // Call custom handler after formik's handleChange
                                }}
                                onBlur={formik.handleBlur}
                              >
                                <option value="">Choose...</option>
                                {stateOptions.map((option) => (
                                  <option
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.label}
                                  </option>
                                ))}
                              </select>
                              {formik.errors.state ? (
                                <div className="error">
                                  {formik.errors.state}
                                </div>
                              ) : null}

                              {showOtherInputState && (
                                <input
                                  type="text"
                                  className="form-control mt-2"
                                  id="otherState"
                                  name="otherState"
                                  placeholder="Enter other state"
                                  value={otherState}
                                  onChange={(e) =>
                                    setOtherState(e.target.value)
                                  }
                                />
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-5">
                          <div className="row mb-3 form-fieldc">
                            <label className="col-md-3 col-sm-4 col-form-label text-nowrap">
                              Zip
                            </label>
                            <div className="col-md-9 col-sm-8">
                              <input
                                type="text"
                                className="form-control"
                                id="zip"
                                name="zip"
                                value={formik.values.zip}
                                maxLength={6}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              />
                              {formik.errors.zip ? (
                                <div className="error">{formik.errors.zip}</div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row ">
                    <div className="col-lg-6 col-xl-6 col-md-6">
                      <div className="row mb-3 form-fieldc">
                        <label className="col-sm-4 col-form-label">Phone</label>
                        <div className="col-sm-8">
                          <input
                            type="text"
                            className="form-control"
                            id="phone"
                            name="phone"
                            value={formik.values.phone}
                            maxLength={10}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.errors.phone ? (
                            <div className="error">{formik.errors.phone}</div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-5 col-lg-6 col-md-6">
                      <div className="row mb-3 form-fieldc">
                        <label className="col-md-3 col-sm-4 col-form-label text-nowrap ps-3 ps-lg-5">
                          Email
                        </label>
                        <div className="col-md-9 col-sm-8">
                          <input
                            type="email"
                            className="form-control"
                            id="email"
                            name="email"
                            value={formik.values.email}
                            maxLength={256}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.errors.email ? (
                            <div className="error">{formik.errors.email}</div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row mb-3 form-fieldc d-flex align-items-center">
                        <label className="col-md-2 col-sm-4 col-form-label">
                          Employees
                        </label>
                        <div className="col-md-10 col-sm-8 h_50">
                          {employeeOptions.map((option) => (
                            <div
                              key={option.value}
                              className="form-check form-check-inline py-2 pe-2"
                            >
                              <input
                                className="form-check-input mr-1"
                                type="radio"
                                name="employees"
                                value={option.value} // Bind directly to the option value
                                id={`employeeNumber${option.value.replace(
                                  /\s+/g,
                                  ""
                                )}`}
                                checked={
                                  formik.values.employees === option.value
                                } // Check against formik values
                                onChange={formik.handleChange} // Use formik handleChange to update form values
                              />
                              <label
                                className="form-check-label font14"
                                htmlFor={`employeeNumber${option.value.replace(
                                  /\s+/g,
                                  ""
                                )}`}
                              >
                                {option.label}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row ">
                    <div className="col-md-6 col-xl-6">
                      <div className="row mb-3 form-fieldc">
                        <label className="col-sm-4 col-form-label">Tags</label>
                        <div className="col-sm-8">
                          <Select
                            isSearchable={false}
                            isClearable={true}
                            isMulti
                            name="tags"
                            className="form-control"
                            defaultValue={selectedTagsSelected}
                            onChange={(e) => {
                              setSelectedTagsSelected(e);

                              const dataToSet = [];
                              for (let index = 0; index < e.length; index++) {
                                const element = e[index];
                                dataToSet.push(element.value);
                              }
                              setSelectedTags(dataToSet);
                            }}
                            options={tags ? tags : []}
                          />
                         
                          {formik.errors.experience ? (
                            <div className="error">
                              {formik.errors.experience}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div className="col-md-6 col-lg-12 col-xl-6">
                      <div className="row mb-3 form-fieldc">
                        <label className="col-sm-4 col-form-label" />
                        <div className="col-sm-8">
                          <button
                            className="btn addsubmit-btn green-btn me-3 mb-3"
                            type="submit"
                          >
                            Update
                          </button>
                          <Link
                            to="/company"
                            className="btn addsubmit-btn grey-btn  mb-3"
                          >
                            Cancel
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          {/* /Companies */}
        </div>
        {/*/Row*/}
        {/* Companies */}
      </div>
    </div>
  );
};

export default EditCompany;
