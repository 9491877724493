// Api Call Custom Hook
import { useContext } from "react";
import axios from "axios";

import { useNavigate } from "react-router-dom";
import { Context } from "../context/Context";

const useApiAxios = () => {
  axios.defaults.headers.common["Authorization"] =
    localStorage.getItem("bearerTokenAdmin");
  axios.defaults.headers.common["Content-Type"] = "application/json";

  // You can also set headers for specific request methods
  axios.defaults.headers.get["Accept"] = "application/json";
  const {
    userData,
    setUserData,

    setIsAuthorized,
    setIsLoading,
    openInfoSnackbar,
    openErrorSnackbar,
    updateCount,
    setUpdateCount,
  } = useContext(Context);
  // all the URL for and endpoints
  const ENDPOINT = process.env.REACT_APP_ENDPOINT;
  const LOGIN = "/";
  const HOME = "/";
  const LOGOUT = "/logout";
  const CREATE_COURSE = "/create/course";
  const GET_ALL_COURSE = "/getall/course";
  const GET_CONTACT = "/get/Contact";
  const DELETE_COURSE = "/delete/course";
  const DELETE_EMAIL_TEMPLATE = "/delete/email/template";
  const CREATE_PRODUCT = "/upload/video";
  const UPDATE_COURSE_VIDEO = "/update/video";
  const DELETE_CONTACT = "/delete/contact";
  const DELETE_VIDEO_MODULE_CONTACT = "/delete/video/module";
  const REPLY_CONTACT = "/reply/contact";
  const UPDATE_COURSE = "/update/course";
  const GET_COURSE = "/get/course";
  const GET_ALL_VIDEO = "/getall/video";
  const DELETE_VIDEO = "/delete/video";
  const GET_COURSE_VIDEO = "/get/video";
  const UPDATE_COURSE_VIEW = "/update/course/view";
  const UPDATE_VIDEO_VIEW = "/update/video/view";
  const UPDATE_VIDEO_MODULE_VIEW = "/update/video/module/view";
  const CREATE_VIDEO_MODULE = "/create/video/module";
  const GET_ALL_VIDEO_MODULE = "/getall/video/module";
  const UPDATE_VIDEO_MODULE = "/update/video/module";
  const FORGET_PASSWORD = "/forget/password";
  const CHANGE_FORGET_PASSWORD = "/forget/password";
  const CREATE_COMPANY = "/create/company";
  const GET_ALL_COMPANY = "/getall/company";
  const CREATE_PHYSICAL_THERAPIST = "/register/physicalTherapist";
  const GET_ALL_PHYSICAL_THERAPIST = "/getall/physicalTherapist";
  const DELETE_PHYSICAL_THERAPIST = "/delete/physicalTherapist";
  const GET_PHYSICAL_THERAPIST = "/get/physicalTherapist";
  const UPDATE_PHYSICAL_THERAPIST = "/update/physicalTherapist";
  const DELETE_COMPANY = "/delete/company";
  const GET_COMPANY = "/get/company";
  const UPDATE_COMPANY = "/update/company";
  const CREATE_EMPLOYEE = "/create/singleEmployee";
  const GET_EMPLOYEE_BY_COMPANY = "/getAll/EmployeeByCompanyId";
  const DELETE_TAG = "/delete/tag";
  const CREATE_TAG = "/create/tag";
  const CREATE_EMAIL_TEMPLATE = "/create/email/template";
  const CREATE_EMAIL_AUTO = "/create/email/auto";
  const GET_TAG = "/get/tag";
  const GET_EMAIL_TEMPLATE = "/getall/email/template";
  const GET_EMAIL_AUTO = "/getall/email/auto";
  const DELETE_EMAIL_AUTO = "/delete/email/auto";
  const GET_EMAIL_LIST = "/getAll/email/list";
  const UPDATE_EMAIL_TEMPLATE = "/update/email/template";
  const GET_ALL_TAG = "/getall/tag";
  const UPDATE_TAG = "/update/tag";
  const CREATE_PATIENT = "/create/singlePatient";
  const GET_ALL_PHYSICAL_THERAPIST_PATIENT =
    "/getAll/PatientByPhysicalTherapistId";
  const DELETE_PHYSICAL_THERAPIST_PATIENT = "/delete/singlePatient";
  const UPDATE_PHYSICAL_THERAPIST_PATIENT = "/update/singlePatient";
  const DELETE_EMPLOYEE = "/delete/singleEmployee";
  const GET_EMPLOYEE_BY_ID = "/get/singleEmployeeById";

  const UPDATE_EMPLOYEE = "/update/SingleEmployee";
  const GET_EMAIL = "/getAll/adminEmails ";
  const GET_PATIENT_BY_ID = "/pt/get/user/byId";
  const CREATE_OFFER = "/create/offer";
  const GET_ALL_OFFER = "/getall/offer";
  const GET_OFFER = "/get/offer";
  const UPDATE_OFFER = "/update/offer";
  const DELETE_OFFER = "/delete/offer";
  const REGISTER = "/create/physicalTherapist";
  const CREATE_SUBSCRIPTION = "/create/subscription";
  const UPDATE_SUBSCRIPTION = "/update/subscription";
  const GET_ALL_PATIENT = "/pt/getAll/Patient";
  const UPDATE_PHYSICAL_THERAPIST_PROFILE = "/update/physicalTherapist/Profile";
  const GET_ALL_PAYMENT = "/stripe/get/subscriptions";
  const UPDATE_PHYSICAL_THERAPIST_PROFILE_IMAGE =
    "/upload/physicalTherapist/Profile/image";
  const GET_ALL_PAIN_HUB = "/get/pain/hib";
  const GET_ALL_QUESTION_HUB = "/get/question/by/hib";
  const GET_ALL_QUESTION_VIDEO_MODULE = "/get/question/video/module";
  const GET_ALL_QUESTION_VIDE = "/get/question/video";
  const CREATE_QUESTION = "/create/question";
  const UPDATE_QUESTION = "/update/question";
  const GET_QUESTION_BY_Id = "/get/question/by/id";
  const DELETE_QUESTION = "/delete/question";
  const DELETE_OPTION_ACTION = "/delete/option/action";
  const ADD_OPTION_ACTION = "/add/option/action";
  const EDIT_OPTION_ACTION = "/edit/option/action";
  const GET_DASHBOARD_DETAIL = "/dashboard/data";
  const UPDATE_PASSWORD = "/profile/update/password";
  const DASHBOARD_TRANSACTION = "/dashboard/transaction";
  const PAIN_SCALE = "/dashboard/pain";
  const COMPANY_GRAPH = "/company/pain/graph";
  const PT_GRAPH = "/pt/pain/graph";
  const GET_WEBSITE_CONTENT = "/get/website/content";
  const UPDATE_WEBSITE_CONTENT = "/update/website/content";
  const UPDATE_WEBSITE_CONTENT_IMAGE = "/update/website/content/image";
  const UPDATE_WEBSITE_CONTENT_VIDEO = "/update/website/content/video";
  const SATISFACTION_SCALE = "/dashboard/customerSatisfaction";
  const COMPANY_SATISFACTION_GRAPH = "/company/customerSatisfaction/graph";
  const PT_SATISFACTION_GRAPH = "/pt/customerSatisfaction/graph";
  const DASHBOARD_TRANSACTION_EXPORT_DATA = "/dashboard/transaction/exportData";
  const DASHBOARD_PAIN_EXPORT_DATA = "/dashboard/pain/exportData";
  const DASHBOARD_SATISFACTION_EXPORT_DATA =
    "/dashboard/customerSatisfaction/exportData";
  const PT_PAIN_EXPORT_DATA = "/pt/pain/exportData";
  const PT_SATISFACTION_EXPORT_DATA = "/pt/customerSatisfaction/exportData";
  const GET_USER_PAIN_EXPORT_DATA = "/pt/get/userPain/exportData";
  const SEND_MULTI_EMAIL = "/send/multi/email";
  const GET_USER_SATISFACTION_EXPORT_DATA =
    "/pt/get/userSatisfaction/exportData";
  const COMPANY_PAIN_EXPORT_DATA = "/company/pain/exportData";
  const COMPANY_SATISFACTION_EXPORT_DATA =
    "/company/customerSatisfaction/exportData";
  const AVG_ACTIVITY_SCALE = "/dashboard/avgActivity";
  const COMPANY_ACTIVITY_GRAPH = "/company/avgActivity/graph";
  const PT_ACTIVITY_GRAPH = "/pt/avgActivity/graph";
  const DASHBOARD_ACTIVITY_EXPORT_DATA =
    "/dashboard/avgActivity/exportData";
  const PT_ACTIVITY_EXPORT_DATA = "/pt/avgActivity/exportData";
  const COMPANY_ACTIVITY_EXPORT_DATA =
    "/company/avgActivity/exportData";
  const GET_USER_ACTIVITY_EXPORT_DATA =
    "/pt/get/avgActivity/exportData";
  const SELECT_TAG_ON_COMPANY = "/select/tag/company";
  const SELECT_TAG_ON_PHYSICAL_THERAPIST = "/select/tag/physicalTherapist";
  const SELECT_TAG_ON_EMPLOYEES = "/select/tag/employees";
  const SELECT_TAG_ON_PATIENT = "/select/tag/patient";
  const SEARCH = "/search"
  const CREATE_APPOINTMENT = "/create/appointmentUrl"
  const GET_APPOINTMENT_LEAD = "/get/appointmentUrl/lead"
  const EMAIL_EXPORT_DATA = "/email/exportData";
  const SELECT_TAG_ON_EMAIL = "/select/tag/email";
  const COMPANY_EXPORT_DATA = "/company/exportData";
  const PT_EXPORT_DATA = "/physicalTherapist/exportData";
  const EMPLOYEE_EXPORT_DATA = "/employee/exportData";
  const PATIENT_EXPORT_DATA = "/patient/exportData";
  const COMPANY_SCALE_DATA = "/get/scale/data/company?id=";
  const PT_SCALE_DATA = "/get/scale/data/physicalTherapist?id=";
  const PT_PATIENT_EXPORT_DATA = "/pt/export/PT/Patient";
  const COMPANY_PAIN_COUNT_EXPORT_DATA = "/company/pain/count/exportData";
  const COMPANY_ACTIVITY_COUNT_EXPORT_DATA = "/company/activity/count/exportData";
  const PT_PAIN_COUNT_EXPORT_DATA = "/pt/pain/count/exportData";
  const PT_ACTIVITY_COUNT_EXPORT_DATA = "/pt/activity/count/exportData";
  const CANCEL_SUBSCRIPTION = "/cancel/subscription";
  const RENEW_SUBSCRIPTION = "/re-new/subscription";
  const SEND_EMAIL_TO_RESUBSCRIBE_API_FOR_COMPANY = "/company/sendEmailToResubscribeApiForCompanyApi";

  const navigate = useNavigate();

  axios.interceptors.response.use(
    response => response, // If the response is successful, just return it
    error => {
      if (error.response && error.response.status === 401) {
        openErrorSnackbar(error.response.message)
        setIsAuthorized(false)

        navigate("/")
      }
      return Promise.reject(error);
    }
  );

  const forgetPasswordApi = async ({ email }) => {
    try {
      setIsLoading(true);
      const res = await axios.post(`${ENDPOINT}${FORGET_PASSWORD}`, { email });
      setIsLoading(false);
      openInfoSnackbar(res.data.message);
      return { error: false, data: res.data.data };
    } catch (error) {
      openErrorSnackbar(error.response.data.message);
      setIsLoading(false);
      return { error: true, data: error.response.data };
    }
  };

  const getDashboardDetail = async (role) => {
    try {
      setIsLoading(true);
      const res = await axios.get(
        `${ENDPOINT}${GET_DASHBOARD_DETAIL}?dashboardType=${role}`
      );
      setIsLoading(false);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      return { error: true, data: error.response.data };
    }
  };

  const loginApi = async ({ password, email, loggedIn }) => {
    try {
      setIsLoading(true);
      const res = await axios.post(`${ENDPOINT}${LOGIN}`, {
        password,
        email,
        loggedIn,
      });
      setUserData(res.data.data.admin);
      localStorage.setItem("bearerTokenAdmin", res.data.data.token);
      setIsAuthorized(true);
      navigate("/");
      setIsLoading(false);
    } catch (error) {
      openErrorSnackbar(error.response.data.message);
      setIsLoading(false);
    }
  };
  const registerApi = async ({
    name,
    phone,
    gender,
    email,
    password,
    passwordConfirm,
    dateOfBirth,
    offerId,
    priceStripeId,
  }) => {
    try {
      setIsLoading(true);
      const res = await axios.post(`${ENDPOINT}${REGISTER}`, {
        name,
        phone,
        gender,
        email,
        password,
        passwordConfirm,
        dateOfBirth,
        offerId,
        priceStripeId,
      });
      setUserData(res.data.data.admin);
      localStorage.setItem("bearerTokenAdmin", res.data.data.token);
      setIsAuthorized(true);
      navigate("/");
      setIsLoading(false);
    } catch (error) {
      openErrorSnackbar(error.response.data.message);
      setIsLoading(false);
    }
  };
  const homeApi = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${ENDPOINT}${HOME}`);

      setUserData(res.data.data);
      setIsAuthorized(true);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (!(window.location.pathname.startsWith("/payment"))) {
        navigate("/");
      }
    }
  };
  const logOutApi = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${ENDPOINT}${LOGOUT}`);
      localStorage.removeItem("bearerTokenAdmin");
      setUserData({});
      setIsAuthorized(false);
      navigate("/");
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      navigate("/");
    }
  };
  const createCourseApi = async (formData) => {
    try {
      setIsLoading(true);
      const res = await axios.post(`${ENDPOINT}${CREATE_COURSE}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setIsLoading(false);
      openInfoSnackbar(res.data.message);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      openErrorSnackbar(error.response.data.message);
      return { error: true, data: error.response.data };
    }
  };
  const getAllCourse = async (page, limit, sort, search) => {
    try {
      setIsLoading(true);
      const res = await axios.get(
        `${ENDPOINT}${GET_ALL_COURSE}?page=${page}&limit=${limit}&sort=${sort}&search=${search}`
      );
      setIsLoading(false);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      return { error: true, data: error.response.data };
    }
  };

  const getContactData = async (page, limit) => {
    try {
      setIsLoading(true);
      const res = await axios.get(
        `${ENDPOINT}${GET_CONTACT}?page=${page}&limit=${limit}`
      );
      setIsLoading(false);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      return { error: true, data: error.response.data };
    }
  };
  const handleCourseDelete = async (id) => {
    try {
      setIsLoading(true);
      const res = await axios.delete(`${ENDPOINT}${DELETE_COURSE}?id=${id}`);
      openInfoSnackbar(res.data.message);
      setIsLoading(false);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      openErrorSnackbar(error.response.data.message);
      return { error: true, data: error.response.data };
    }
  };
  const createProductApi = async (formData, filename) => {
    try {
      setIsLoading(true);
      const res = await axios.post(`${ENDPOINT}${CREATE_PRODUCT}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setIsLoading(false);
      await uploadProductApi(filename, res.data.data._id);
      openInfoSnackbar(res.data.message);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      openErrorSnackbar(error.response.data.message);
      return { error: true, data: error.response.data };
    }
  };
  const uploadProductApi = async (filename, id) => {
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("filename", filename);
      const res = await axios.patch(
        `${ENDPOINT}${CREATE_PRODUCT}?id=${id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setIsLoading(false);
      openInfoSnackbar(res.data.message);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      openErrorSnackbar(error.response.data.message);
      return { error: true, data: error.response.data };
    }
  };
  const deleteContactApi = async (id) => {
    try {
      setIsLoading(true);
      const res = await axios.delete(`${ENDPOINT}${DELETE_CONTACT}?id=${id}`);
      openInfoSnackbar(res.data.message);
      setIsLoading(false);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      openErrorSnackbar(error.response.data.message);
      return { error: true, data: error.response.data };
    }
  };
  const replyContactApi = async ({ id, reply, title }) => {
    try {
      setIsLoading(true);
      const res = await axios.post(`${ENDPOINT}${REPLY_CONTACT}?id=${id}`, {
        title,
        reply,
      });
      openInfoSnackbar(res.data.message);
      setIsLoading(false);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      openErrorSnackbar(error.response.data.message);
      return { error: true, data: error.response.data };
    }
  };
  const updateCourseApi = async (id, data) => {
    try {
      setIsLoading(true);
      const res = await axios.patch(
        `${ENDPOINT}${UPDATE_COURSE}?id=${id}`, // Appending courseId to the URL
        data,
        {}
      );
      setIsLoading(false);
      openInfoSnackbar(res.data.message);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      openErrorSnackbar(error.response.data.message);
      return { error: true, data: error.response.data };
    }
  };
  const updateCourseIconApi = async (id, formData) => {
    try {
      setIsLoading(true);
      const res = await axios.put(
        `${ENDPOINT}${UPDATE_COURSE}?id=${id}`, // Appending courseId to the URL
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setIsLoading(false);
      openInfoSnackbar(res.data.message);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      openErrorSnackbar(error.response.data.message);
      return { error: true, data: error.response.data };
    }
  };
  const getCourseApi = async (id) => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${ENDPOINT}${GET_COURSE}?id=${id}`);

      setIsLoading(false);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      return { error: true, data: error.response.data };
    }
  };
  const getAllVideo = async (page, limit, courseId) => {
    try {
      setIsLoading(true);
      const res = await axios.get(
        `${ENDPOINT}${GET_ALL_VIDEO}?page=${page}&limit=${limit}&courseId=${courseId}`
      );

      setIsLoading(false);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      return { error: true, data: error.response.data };
    }
  };
  const getAllVideoModule = async (courseId, page, sort, search) => {
    try {
      setIsLoading(true);
      const res = await axios.get(
        `${ENDPOINT}${GET_ALL_VIDEO_MODULE}?courseId=${courseId}&sort=${sort}&search=${search}`
      );

      setIsLoading(false);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      return { error: true, data: error.response.data };
    }
  };
  const handleVideoDelete = async (id) => {
    try {
      setIsLoading(true);
      const res = await axios.delete(`${ENDPOINT}${DELETE_VIDEO}?id=${id}`);
      openInfoSnackbar(res.data.message);
      setIsLoading(false);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      openErrorSnackbar(error.response.data.message);
      return { error: true, data: error.response.data };
    }
  };
  const getCourseVideoApi = async (id) => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${ENDPOINT}${GET_COURSE_VIDEO}?id=${id}`);

      setIsLoading(false);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);

      return { error: true, data: error.response.data };
    }
  };
  const updateCourseViewApi = async ({ id, publicView }) => {
    try {
      setIsLoading(true);
      const res = await axios.patch(
        `${ENDPOINT}${UPDATE_COURSE_VIEW}?id=${id}`, // Appending courseId to the URL
        { publicView }
      );
      setIsLoading(false);
      openInfoSnackbar(res.data.message);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      openErrorSnackbar(error.response.data.message);
      return { error: true, data: error.response.data };
    }
  };
  const updateCourseVideoApi = async (id, data) => {
    try {
      setIsLoading(true);
      const res = await axios.post(
        `${ENDPOINT}${UPDATE_COURSE_VIDEO}?id=${id}`, // Appending courseId to the URL
        data
      );
      setIsLoading(false);
      openInfoSnackbar(res.data.message);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      openErrorSnackbar(error.response.data.message);
      return { error: true, data: error.response.data };
    }
  };
  const updateCourseVideoThumbnailApi = async (id, data) => {
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("thumbnail", data);
      const res = await axios.patch(
        `${ENDPOINT}${UPDATE_COURSE_VIDEO}?id=${id}`, // Appending courseId to the URL
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setIsLoading(false);
      openInfoSnackbar(res.data.message);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      openErrorSnackbar(error.response.data.message);
      return { error: true, data: error.response.data };
    }
  };
  const updateCourseVideoFileApi = async (id, data) => {
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("filename", data);
      const res = await axios.put(
        `${ENDPOINT}${UPDATE_COURSE_VIDEO}?id=${id}`, // Appending courseId to the URL
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setIsLoading(false);
      openInfoSnackbar(res.data.message);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      openErrorSnackbar(error.response.data.message);
      return { error: true, data: error.response.data };
    }
  };
  const updateCourseVideoViewApi = async ({ id, publicView }) => {
    try {
      setIsLoading(true);
      const res = await axios.patch(
        `${ENDPOINT}${UPDATE_VIDEO_VIEW}?id=${id}`, // Appending courseId to the URL
        { publicView }
      );
      setIsLoading(false);
      openInfoSnackbar(res.data.message);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      openErrorSnackbar(error.response.data.message);
      return { error: true, data: error.response.data };
    }
  };
  const updateCourseVideoModuleViewApi = async ({ id, publicView }) => {
    try {
      setIsLoading(true);
      const res = await axios.patch(
        `${ENDPOINT}${UPDATE_VIDEO_MODULE_VIEW}?id=${id}`, // Appending courseId to the URL
        { publicView }
      );
      setIsLoading(false);
      openInfoSnackbar(res.data.message);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      openErrorSnackbar(error.response.data.message);
      return { error: true, data: error.response.data };
    }
  };
  const createVideoModuleApi = async (data) => {
    try {
      setIsLoading(true);
      const res = await axios.post(`${ENDPOINT}${CREATE_VIDEO_MODULE}`, data);
      setIsLoading(false);

      openInfoSnackbar(res.data.message);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      openErrorSnackbar(error.response.data.message);
      return { error: true, data: error.response.data };
    }
  };
  const handleVideoModuleDelete = async (id) => {
    try {
      setIsLoading(true);
      const res = await axios.delete(
        `${ENDPOINT}${DELETE_VIDEO_MODULE_CONTACT}?id=${id}`
      );
      openInfoSnackbar(res.data.message);
      setIsLoading(false);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      openErrorSnackbar(error.response.data.message);
      return { error: true, data: error.response.data };
    }
  };
  const updateVideoModuleApi = async (id, data) => {
    try {
      setIsLoading(true);
      const res = await axios.post(
        `${ENDPOINT}${UPDATE_VIDEO_MODULE}?id=${id}`, // Appending courseId to the URL
        data,
        {}
      );
      setIsLoading(false);
      openInfoSnackbar(res.data.message);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      openErrorSnackbar(error.response.data.message);
      return { error: true, data: error.response.data };
    }
  };
  const changePasswordOTP = async ({
    otp,
    password,
    passwordConfirm,
    email,
  }) => {
    try {
      setIsLoading(true);
      const res = await axios.patch(`${ENDPOINT}${CHANGE_FORGET_PASSWORD}`, {
        otp,
        password,
        passwordConfirm,
        email,
      });
      setIsLoading(false);

      openInfoSnackbar(res.data.message);
      navigate("/");
    } catch (error) {
      openErrorSnackbar(error.response.data.message);
      setIsLoading(false);
    }
  };
  const createCompany = async (formData) => {
    try {
      setIsLoading(true);
      const res = await axios.post(
        `${ENDPOINT}${CREATE_COMPANY}`,
        formData,
        {}
      );
      setIsLoading(false);
      openInfoSnackbar(res.data.message);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      openErrorSnackbar(error.response.data.message);
      return { error: true, data: error.response.data };
    }
  };
  const getAllCompany = async (page, limit, sort, search, tag) => {
    try {
      setIsLoading(true);
      const token = localStorage.getItem("bearerTokenAdmin");
      const url = `${ENDPOINT}${GET_ALL_COMPANY}?page=${page}&limit=${limit}&sort=${sort}&search=${search}&tag=${tag}`;
      const res = await axios.get(url, {
        headers: {
          Authorization: token,
          "Content-Type": "multipart/form-data",
        },
      });
      setIsLoading(false);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      return { error: true, data: error.response.data };
    }
  };
  const createPhysicaltherapistApi = async (formData) => {
    try {
      setIsLoading(true);
      const res = await axios.post(
        `${ENDPOINT}${CREATE_PHYSICAL_THERAPIST}`,
        formData
      );
      setIsLoading(false);

      openInfoSnackbar(res.data.message);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      openErrorSnackbar(error.response.data.message);
      return { error: true, data: error.response.data };
    }
  };
  const getAllPhysicaltherapist = async (page, limit, sort, search, tag) => {
    try {
      setIsLoading(true);
      const token = localStorage.getItem("bearerTokenAdmin");
      const url = `${ENDPOINT}${GET_ALL_PHYSICAL_THERAPIST}?page=${page}&limit=${limit}&sort=${sort}&search=${search}&tag=${tag}`;
      const res = await axios.get(url, {
        headers: {
          Authorization: token,
        },
      });
      setIsLoading(false);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      return { error: true, data: error.response.data };
    }
  };
  const deletePhysicaltherapistApi = async (id) => {
    try {
      setIsLoading(true);
      const res = await axios.delete(
        `${ENDPOINT}${DELETE_PHYSICAL_THERAPIST}?id=${id}`
      );
      openInfoSnackbar(res.data.message);
      setIsLoading(false);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      openErrorSnackbar(error.response.data.message);
      return { error: true, data: error.response.data };
    }
  };
  const getPhysicalTherapistApi = async (id, sort, search, tag) => {
    try {
      setIsLoading(true);
      const res = await axios.get(
        `${ENDPOINT}${GET_PHYSICAL_THERAPIST}?id=${id}&sort=${sort}&search=${search}&tag=${tag}`
      );

      setIsLoading(false);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);

      return { error: true, data: error.response.data };
    }
  };
  const updatePhysicalTherapistApi = async (id, data) => {
    try {
      setIsLoading(true);
      const res = await axios.patch(
        `${ENDPOINT}${UPDATE_PHYSICAL_THERAPIST}?id=${id}`, // Appending courseId to the URL
        data,
        {}
      );
      setIsLoading(false);
      openInfoSnackbar(res.data.message);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      openErrorSnackbar(error.response.data.message);
      return { error: true, data: error.response.data };
    }
  };
  const handleCompanyDelete = async (_id) => {
    try {
      setIsLoading(true);
      const res = await axios.delete(`${ENDPOINT}${DELETE_COMPANY}?id=${_id}`);
      openInfoSnackbar(res.data.message);
      setIsLoading(false);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      openErrorSnackbar(error.response.data.message);
      return { error: true, data: error.response.data };
    }
  };
  const getCompany = async (id, sort, search, tag) => {
    try {
      setIsLoading(true);
      const res = await axios.get(
        `${ENDPOINT}${GET_COMPANY}?id=${id}&sort=${sort}&search=${search}&tag=${tag}`
      );
      setIsLoading(false);

      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      return { error: true, data: error.response.data };
    }
  };
  const updateCompanyApi = async (id, data) => {
    try {
      setIsLoading(true);
      const res = await axios.patch(
        `${ENDPOINT}${UPDATE_COMPANY}?id=${id}`, // Appending companyId to the URL
        data,
        {}
      );
      setIsLoading(false);
      openInfoSnackbar(res.data.message);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      openErrorSnackbar(error.response.data.message);
      return { error: true, data: error.response.data };
    }
  };
  const createEmployeeApi = async (companyId, employeeData) => {
    // Accept companyId and employeeData
    try {
      setIsLoading(true);

      const res = await axios.post(
        `${ENDPOINT}${CREATE_EMPLOYEE}?id=${companyId}`, // Pass companyId in the URL
        employeeData, // Pass employeeData directly
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setIsLoading(false);
      openInfoSnackbar(res.data.message);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      openErrorSnackbar(error.response.data.message);
      return { error: true, data: error.response.data };
    }
  };
  const getEmployeeByCompanyId = async (
    companyId,
    employeeData,
    page,
    sort,
    search
  ) => {
    // Accept companyId and employeeData
    const token = localStorage.getItem("bearerTokenAdmin");

    try {
      setIsLoading(true);

      const res = await axios.get(
        `${ENDPOINT}${GET_EMPLOYEE_BY_COMPANY}?id=${companyId}&sort=${sort}&search=${search}`, // Pass companyId in the URL
        employeeData, // Pass employeeData directly
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      setIsLoading(false);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      openErrorSnackbar(error.response.data.message);
      return { error: true, data: error.response.data };
    }
  };
  const getTagsApi = async (id) => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${ENDPOINT}${GET_TAG}`);
      setIsLoading(false);

      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      return { error: true, data: error.response.data };
    }
  };
  const getAllTagsApi = async (id, sort, search) => {
    try {
      setIsLoading(true);
      const res = await axios.get(
        `${ENDPOINT}${GET_ALL_TAG}?sort=${sort ? sort : ""}&search=${search ? search : ""}`);

      setIsLoading(false);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      return { error: true, data: error.response.data };
    }
  };
  const updateTagsApi = async (id, data) => {
    try {
      setIsLoading(true);
      const res = await axios.patch(
        `${ENDPOINT}${UPDATE_TAG}?id=${id}`, // Appending courseId to the URL
        data,
        {}
      );
      setIsLoading(false);
      openInfoSnackbar(res.data.message);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      openErrorSnackbar(error.response.data.message);
      return { error: true, data: error.response.data };
    }
  };
  const createTagsApi = async (data) => {
    try {
      setIsLoading(true);
      const res = await axios.post(`${ENDPOINT}${CREATE_TAG}`, data, {});
      setIsLoading(false);
      openInfoSnackbar(res.data.message);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      openErrorSnackbar(error.response.data.message);
      return { error: true, data: error.response.data };
    }
  };
  const deleteTagsApi = async (id) => {
    try {
      setIsLoading(true);
      const res = await axios.delete(`${ENDPOINT}${DELETE_TAG}?id=${id}`);
      openInfoSnackbar(res.data.message);
      setIsLoading(false);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      openErrorSnackbar(error.response.data.message);
      return { error: true, data: error.response.data };
    }
  };
  const createPatientApi = async (physicalTherapistId, formData) => {
    try {
      setIsLoading(true);
      const res = await axios.post(
        `${ENDPOINT}${CREATE_PATIENT}?id=${physicalTherapistId}`,
        formData
      );
      setIsLoading(false);
      openInfoSnackbar(res.data.message);
      return { error: false, data: res.data };
    } catch (error) {
      return { error: true, data: error.response.data };
    }
  };
  const getAllPhysicaltherapistPatient = async (
    physicalTherapistId,
    page,
    limit,
    sort,
    search,
    tag
  ) => {
    // Accept companyId and employeeData
    const token = localStorage.getItem("bearerTokenAdmin");
    try {
      setIsLoading(true);
      const res = await axios.get(
        `${ENDPOINT}${GET_ALL_PHYSICAL_THERAPIST_PATIENT}?id=${physicalTherapistId}&page=${page}&limit=${limit}&sort=${sort}&search=${search}&tag=${tag}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      setIsLoading(false);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      openErrorSnackbar(error.response.data.message);
      return { error: true, data: error.response.data };
    }
  };
  const deletePhysicaltherapistPatientApi = async (id) => {
    try {
      setIsLoading(true);
      const res = await axios.delete(
        `${ENDPOINT}${DELETE_PHYSICAL_THERAPIST_PATIENT}?patientId=${id}`
      );
      openInfoSnackbar(res.data.message);
      setIsLoading(false);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      openErrorSnackbar(error.response.data.message);
      return { error: true, data: error.response.data };
    }
  };
  const updatePhysicalTherapistPatient = async (id, data) => {
    try {
      setIsLoading(true);
      const res = await axios.patch(
        `${ENDPOINT}${UPDATE_PHYSICAL_THERAPIST_PATIENT}?patientId=${id}`, // Appending courseId to the URL
        data,
        {}
      );
      setIsLoading(false);
      openInfoSnackbar(res.data.message);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      openErrorSnackbar(error.response.data.message);
      return { error: true, data: error.response.data };
    }
  };


  const handleDeleteEmployeeApi = async (_id) => {
    try {
      setIsLoading(true);
      const res = await axios.delete(
        `${ENDPOINT}${DELETE_EMPLOYEE}?employeeId=${_id}`
      );
      openInfoSnackbar(res.data.message);
      setIsLoading(false);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      openErrorSnackbar(error.response.data.message);
      return { error: true, data: error.response.data };
    }
  };
  const getEmployeeById = async (id) => {
    try {
      setIsLoading(true);
      const res = await axios.get(
        `${ENDPOINT}${GET_EMPLOYEE_BY_ID}?employeeId=${id}`
      );
      setIsLoading(false);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      return { error: true, data: error.response.data };
    }
  };

  const getPatientById = async (id) => {
    try {
      setIsLoading(true);
      const res = await axios.post(`${ENDPOINT}${GET_PATIENT_BY_ID}?id=${id}`);
      setIsLoading(false);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      return { error: true, data: error.response.data };
    }
  };

  const updateEmployeeApi = async (id, data) => {
    try {
      setIsLoading(true);
      const res = await axios.patch(
        `${ENDPOINT}${UPDATE_EMPLOYEE}?employeeId=${id}`, // Appending courseId to the URL
        data,
        {}
      );
      setIsLoading(false);
      openInfoSnackbar(res.data.message);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      openErrorSnackbar(error.response.data.message);
      return { error: true, data: error.response.data };
    }
  };
 
  const getEmail = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${ENDPOINT}${GET_EMAIL}`);
      setIsLoading(false);
      // Split the data string into an array of email addresses
      const emailArray = res.data.data.split(",");

      // Remove any empty or whitespace-only entries
      const filteredEmails = emailArray.filter((email) => email.trim() !== "");
      return { error: false, data: filteredEmails };
    } catch (error) {
      setIsLoading(false);
      return { error: true, data: error.response.data };
    }
  };

  const createOfferApi = async (data) => {
    // Accept companyId and data
    try {
      setIsLoading(true);

      const res = await axios.post(
        `${ENDPOINT}${CREATE_OFFER}`, // Pass companyId in the URL
        data, // Pass employeeData directly
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setIsLoading(false);
      openInfoSnackbar(res.data.message);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      openErrorSnackbar(error.response.data.message);
      return { error: true, data: error.response.data };
    }
  };
  const updateOfferApi = async (id, data) => {
    // Accept companyId and data
    try {
      setIsLoading(true);

      const res = await axios.patch(
        `${ENDPOINT}${UPDATE_OFFER}?id=${id}`, // Pass companyId in the URL
        data // Pass employeeData directly
      );
      setIsLoading(false);
      openInfoSnackbar(res.data.message);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      openErrorSnackbar(error.response.data.message);
      return { error: true, data: error.response.data };
    }
  };
  const getOfferById = async (id) => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${ENDPOINT}${GET_OFFER}?id=${id}`);
      setIsLoading(false);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      return { error: true, data: error.response.data };
    }
  };
  const handleDeleteOfferApi = async (id) => {
    try {
      setIsLoading(true);
      const res = await axios.delete(`${ENDPOINT}${DELETE_OFFER}?id=${id}`);
      openInfoSnackbar(res.data.message);
      setIsLoading(false);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      openErrorSnackbar(error.response.data.message);
      return { error: true, data: error.response.data };
    }
  };
  const getOfferApi = async (page, sort, search) => {
    try {
      setIsLoading(true);
      const res = await axios.get(
        `${ENDPOINT}${GET_ALL_OFFER}?sort=${sort}&search=${search}`
      );
      setIsLoading(false);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);

      return { error: true, data: error.response.data };
    }
  };
  const updateSubscription = async (session_id) => {
    try {
      setIsLoading(true);
      const res = await axios.post(`${ENDPOINT}${UPDATE_SUBSCRIPTION}`, {
        session_id,
      });
      setUpdateCount(updateCount + 1);
      setIsLoading(false);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      return { error: true, data: null };
    }
  };
  const createSubscription = async (priceId, offerId) => {
    try {
      setIsLoading(true);
      const res = await axios.post(`${ENDPOINT}${CREATE_SUBSCRIPTION}`, {
        priceId,
        offerId,
      });
      setIsLoading(false);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      return { error: true, data: null };

    }
  };
  const getAllPatient = async (id, sort, search, tag) => {
    // Accept companyId and employeeData
    const token = localStorage.getItem("bearerTokenAdmin");
    try {
      setIsLoading(true);
      const res = await axios.get(
        `${ENDPOINT}${GET_ALL_PATIENT}?sort=${sort}&search=${search}&tag=${tag}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      setIsLoading(false);

      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      openErrorSnackbar(error.response.data.message);
      return { error: true, data: error.response.data };
    }
  };
  const updatePhysicalTherapistProfileData = async (data) => {
    try {
      setIsLoading(true);
      const res = await axios.post(
        `${ENDPOINT}${UPDATE_PHYSICAL_THERAPIST_PROFILE}`, // Appending courseId to the URL
        data,
        {}
      );
      setIsLoading(false);
      openInfoSnackbar(res.data.message);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      openErrorSnackbar(error.response.data.message);
      return { error: true, data: error.response.data };
    }
  };
  const updatePhysicalTherapistProfileImage = async (formData) => {
    try {
      setIsLoading(true);
      const res = await axios.post(
        `${ENDPOINT}${UPDATE_PHYSICAL_THERAPIST_PROFILE_IMAGE}`, // Appending courseId to the URL
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setIsLoading(false);
      openInfoSnackbar(res.data.message);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      openErrorSnackbar(error.response.data.message);
      return { error: true, data: error.response.data };
    }
  };
  const getAllPaymentsApi = async (type, page, limit, sort, search) => {
    try {
      setIsLoading(true);
      const res = await axios.get(
        `${ENDPOINT}${GET_ALL_PAYMENT}?paymentFor=${type}&page=${page}&limit=${limit}&sort=${sort}&search=${search}`
      );
      setIsLoading(false);

      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      return { error: true, data: error.response.data };
    }
  };
  const getAllPainHab = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${ENDPOINT}${GET_ALL_PAIN_HUB}`);

      setIsLoading(false);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      return { error: true, data: error.response.data };
    }
  };
  const getQuestionByHabIdApi = async (habId) => {
    try {
      setIsLoading(true);
      const res = await axios.get(
        `${ENDPOINT}${GET_ALL_QUESTION_HUB}?habId=${habId}`
      );

      setIsLoading(false);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      return { error: true, data: error.response.data };
    }
  };
  const getQuestionVideoApi = async (habId) => {
    try {
      setIsLoading(true);
      const res = await axios.get(
        `${ENDPOINT}${GET_ALL_QUESTION_VIDE}?habId=${habId}`
      );
      setIsLoading(false);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      return { error: true, data: error.response.data };
    }
  };
  const getQuestionVideoModuleApi = async (habId) => {
    try {
      setIsLoading(true);
      const res = await axios.get(
        `${ENDPOINT}${GET_ALL_QUESTION_VIDEO_MODULE}?habId=${habId}`
      );

      setIsLoading(false);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      return { error: true, data: error.response.data };
    }
  };
  const createQuestionApi = async (data, hubId) => {
    try {
      setIsLoading(true);
      const res = await axios.post(
        `${ENDPOINT}${CREATE_QUESTION}?hubId=${hubId}`, // Appending courseId to the URL
        data,
        {}
      );
      setIsLoading(false);
      openInfoSnackbar(res.data.message);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      openErrorSnackbar(error.response.data.message);
      return { error: true, data: error.response.data };
    }
  };

  const getQuestionByIdApi = async (questionId) => {
    try {
      setIsLoading(true);
      const res = await axios.get(
        `${ENDPOINT}${GET_QUESTION_BY_Id}?questionId=${questionId}`
      );

      setIsLoading(false);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      return { error: true, data: error.response.data };
    }
  };
  const updateQuestionApi = async (data, questionId) => {
    try {
      setIsLoading(true);
      const res = await axios.post(
        `${ENDPOINT}${UPDATE_QUESTION}?questionDbId=${questionId}`, // Appending courseId to the URL
        data,
        {}
      );
      setIsLoading(false);
      openInfoSnackbar(res.data.message);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      openErrorSnackbar(error.response.data.message);
      return { error: true, data: error.response.data };
    }
  };

  const deleteQuestionApi = async (questionDbId) => {
    try {
      setIsLoading(true);
      const res = await axios.delete(
        `${ENDPOINT}${DELETE_QUESTION}?questionDbId=${questionDbId}`
      );
      openInfoSnackbar(res.data.message);
      setIsLoading(false);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      openErrorSnackbar(error.response.data.message);
      return { error: true, data: error.response.data };
    }
  };


  const addOptionActionApi = async (data, id) => {
    try {
      setIsLoading(true);
      const res = await axios.post(
        `${ENDPOINT}${ADD_OPTION_ACTION}?id=${id}`, // Appending courseId to the URL
        data,
        {}
      );
      setIsLoading(false);
      openInfoSnackbar(res.data.message);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      openErrorSnackbar(error.response.data.message);
      return { error: true, data: error.response.data };
    }
  };
  const deleteOptionActionApi = async (id) => {
    try {
      setIsLoading(true);
      const res = await axios.delete(
        `${ENDPOINT}${DELETE_OPTION_ACTION}?id=${id}`
      );
      openInfoSnackbar(res.data.message);
      setIsLoading(false);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      openErrorSnackbar(error.response.data.message);
      return { error: true, data: error.response.data };
    }
  };

  const createEmailTemplateApi = async (data) => {
    try {
      setIsLoading(true);
      const res = await axios.post(
        `${ENDPOINT}${CREATE_EMAIL_TEMPLATE}`,
        data,
        {}
      );
      setIsLoading(false);
      openInfoSnackbar(res.data.message);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      openErrorSnackbar(error.response.data.message);
      return { error: true, data: error.response.data };
    }
  };
  const getAllEmailTemplateApi = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${ENDPOINT}${GET_EMAIL_TEMPLATE}`);
      setIsLoading(false);

      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      return { error: true, data: error.response.data };
    }
  };
  const updateEmailTemplateApi = async (id, data) => {
    try {
      setIsLoading(true);
      const res = await axios.patch(
        `${ENDPOINT}${UPDATE_EMAIL_TEMPLATE}?id=${id}`, // Appending courseId to the URL
        data
      );
      setIsLoading(false);
      openInfoSnackbar(res.data.message);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      openErrorSnackbar(error.response.data.message);
      return { error: true, data: error.response.data };
    }
  };

  const deleteEmailTemplateApi = async (id) => {
    try {
      setIsLoading(true);
      const res = await axios.delete(
        `${ENDPOINT}${DELETE_EMAIL_TEMPLATE}?id=${id}`
      );
      openInfoSnackbar(res.data.message);
      setIsLoading(false);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      openErrorSnackbar(error.response.data.message);
      return { error: true, data: error.response.data };
    }
  };
  const getAllEmailListApi = async (sort, search, page, limit, type, tag) => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${ENDPOINT}${GET_EMAIL_LIST}?sort=${sort}&search=${search}&page=${page}&limit=${limit}&type=${type}&tag=${tag}`);
      setIsLoading(false);

      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      return { error: true, data: error.response.data };
    }
  };

  const createEmailAutoApi = async (data) => {
    try {
      setIsLoading(true);
      const res = await axios.post(`${ENDPOINT}${CREATE_EMAIL_AUTO}`, data, {});
      setIsLoading(false);
      openInfoSnackbar(res.data.message);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      openErrorSnackbar(error.response.data.message);
      return { error: true, data: error.response.data };
    }
  };
  const getAllEmailAutoApi = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${ENDPOINT}${GET_EMAIL_AUTO}`);
      setIsLoading(false);

      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      return { error: true, data: error.response.data };
    }
  };

  const deleteEmailAutoApi = async (id) => {
    try {
      setIsLoading(true);
      const res = await axios.delete(
        `${ENDPOINT}${DELETE_EMAIL_AUTO}?id=${id}`
      );
      openInfoSnackbar(res.data.message);
      setIsLoading(false);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      openErrorSnackbar(error.response.data.message);
      return { error: true, data: error.response.data };
    }
  };
  const changePasswordApi = async (data) => {
    try {
      setIsLoading(true);
      const res = await axios.post(`${ENDPOINT}${UPDATE_PASSWORD}`, data);
      setIsAuthorized(true);

      navigate("/");
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      openErrorSnackbar(error.response.data.message);
    }
  };

  const getTransactionGraphDataApi = async (startDate, endDate) => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${ENDPOINT}${DASHBOARD_TRANSACTION}`, {
        params: { startDate, endDate }
      });
      setIsLoading(false);

      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      return { error: true, data: error.response.data };
    }
  };
  const getPainGraphDataApi = async (startDate, endDate) => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${ENDPOINT}${PAIN_SCALE}`, {
        params: { startDate, endDate }
      });
      setIsLoading(false);

      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      return { error: true, data: error.response.data };
    }
  };

  const getCompanyGraphApi = async (id) => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${ENDPOINT}${COMPANY_GRAPH}?id=${id}`);

      setIsLoading(false);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      openErrorSnackbar(error.response.data.message);
      return { error: true, data: error.response.data };
    }
  };

  const getPTGraphApi = async (id) => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${ENDPOINT}${PT_GRAPH}?id=${id}`);

      setIsLoading(false);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      openErrorSnackbar(error.response.data.message);
      return { error: true, data: error.response.data };
    }
  };

  const getWebsiteContent = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${ENDPOINT}${GET_WEBSITE_CONTENT}`);

      setIsLoading(false);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      openErrorSnackbar(error.response.data.message);
      return { error: true, data: error.response.data };
    }
  };

  const updateWebsiteContent = async (data) => {
    try {
      setIsLoading(true);
      const res = await axios.post(
        `${ENDPOINT}${UPDATE_WEBSITE_CONTENT}`,
        data
      );
      openInfoSnackbar(res.data.message);
      setIsLoading(false);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      openErrorSnackbar(error.response.data.message);
      return { error: true, data: error.response.data };
    }
  };
  const updateWebsiteContentImage = async (formData, name) => {
    try {
      setIsLoading(true);
      const res = await axios.post(
        `${ENDPOINT}${UPDATE_WEBSITE_CONTENT_IMAGE}?name=${name}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      openInfoSnackbar(res.data.message);
      setIsLoading(false);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      openErrorSnackbar(error.response.data.message);
      return { error: true, data: error.response.data };
    }
  };

  const updateWebsiteContentVideo = async (formData, name) => {
    try {
      setIsLoading(true);
      const res = await axios.post(
        `${ENDPOINT}${UPDATE_WEBSITE_CONTENT_VIDEO}?name=${name}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      openInfoSnackbar(res.data.message);
      setIsLoading(false);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      openErrorSnackbar(error.response.data.message);
      return { error: true, data: error.response.data };
    }
  };

  const getSatisfactionGraphDataApi = async (startDate, endDate) => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${ENDPOINT}${SATISFACTION_SCALE}`, {
        params: { startDate, endDate }
      });
      setIsLoading(false);

      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      return { error: true, data: error.response.data };
    }
  };

  const getCompanySatisfactionGraphApi = async (id) => {
    try {
      setIsLoading(true);
      const res = await axios.get(
        `${ENDPOINT}${COMPANY_SATISFACTION_GRAPH}?id=${id}`
      );

      setIsLoading(false);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      openErrorSnackbar(error.response.data.message);
      return { error: true, data: error.response.data };
    }
  };

  const getPtSatisfactionGraphApi = async (id) => {
    try {
      setIsLoading(true);
      const res = await axios.get(
        `${ENDPOINT}${PT_SATISFACTION_GRAPH}?id=${id}`
      );

      setIsLoading(false);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      openErrorSnackbar(error.response.data.message);
      return { error: true, data: error.response.data };
    }
  };

  const getTransactionExportDataApi = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(
        `${ENDPOINT}${DASHBOARD_TRANSACTION_EXPORT_DATA}`,
        {
          responseType: "blob",
        }
      );
      setIsLoading(false);

      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      return { error: true, data: error.response.data };
    }
  };

  const getPainExportDataApi = async (id) => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${ENDPOINT}${DASHBOARD_PAIN_EXPORT_DATA}?id=${id}`,

        {
          responseType: "blob",
        }
      );
      setIsLoading(false);

      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      return { error: true, data: error.response.data };
    }
  };

  const getSatisfactionExportDataApi = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(
        `${ENDPOINT}${DASHBOARD_SATISFACTION_EXPORT_DATA}`,
        {
          responseType: "blob",
        }
      );
      setIsLoading(false);

      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      return { error: true, data: error.response.data };
    }
  };

  const getPtPainExportDataApi = async (id) => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${ENDPOINT}${PT_PAIN_EXPORT_DATA}?id=${id}`, {
        responseType: "blob",
      });
      setIsLoading(false);

      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      return { error: true, data: error.response.data };
    }
  };

  const getPtSatisfactionExportDataApi = async (id) => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${ENDPOINT}${PT_SATISFACTION_EXPORT_DATA}?id=${id}`, {
        responseType: "blob",
      });
      setIsLoading(false);

      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      return { error: true, data: error.response.data };
    }
  };

  const getUserPainExportDataApi = async (id) => {
    try {
      setIsLoading(true);
      const res = await axios.get(
        `${ENDPOINT}${GET_USER_PAIN_EXPORT_DATA}?id=${id}`,
        {
          responseType: "blob",
        }
      );
      setIsLoading(false);

      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      return { error: true, data: error.response.data };
    }
  };

  const getUserSatisfactionExportDataApi = async (id) => {
    try {
      setIsLoading(true);
      const res = await axios.get(
        `${ENDPOINT}${GET_USER_SATISFACTION_EXPORT_DATA}?id=${id}`,
        {
          responseType: "blob",
        }
      );
      setIsLoading(false);

      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      return { error: true, data: error.response.data };
    }
  };

  const getCompanyPainExportDataApi = async (id) => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${ENDPOINT}${COMPANY_PAIN_EXPORT_DATA}?id=${id}`, {
        responseType: "blob",
      });
      setIsLoading(false);

      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      return { error: true, data: error.response.data };
    }
  };

  const getCompanySatisfactionExportDataApi = async (id) => {
    try {
      setIsLoading(true);
      const res = await axios.get(
        `${ENDPOINT}${COMPANY_SATISFACTION_EXPORT_DATA}?id=${id}`,
        {
          responseType: "blob",
        }
      );
      setIsLoading(false);

      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      return { error: true, data: error.response.data };
    }
  };

  const editOptionActionApi = async (data, id) => {
    try {
      setIsLoading(true);
      const res = await axios.post(
        `${ENDPOINT}${EDIT_OPTION_ACTION}?id=${id}`, // Appending courseId to the URL
        data,
        {}
      );
      setIsLoading(false);
      openInfoSnackbar(res.data.message);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      openErrorSnackbar(error.response.data.message);
      return { error: true, data: error.response.data };
    }
  };


  const getActivityGraphDataApi = async (startDate, endDate) => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${ENDPOINT}${AVG_ACTIVITY_SCALE}`, {
        params: { startDate, endDate }
      });
      setIsLoading(false);

      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      return { error: true, data: error.response.data };
    }
  };

  const getCompanyActivityGraphApi = async (id) => {
    try {
      setIsLoading(true);
      const res = await axios.get(
        `${ENDPOINT}${COMPANY_ACTIVITY_GRAPH}?id=${id}`
      );

      setIsLoading(false);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      openErrorSnackbar(error.response.data.message);
      return { error: true, data: error.response.data };
    }
  };

  const getPtActivityGraphApi = async (id) => {
    try {
      setIsLoading(true);
      const res = await axios.get(
        `${ENDPOINT}${PT_ACTIVITY_GRAPH}?id=${id}`
      );

      setIsLoading(false);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      openErrorSnackbar(error.response.data.message);
      return { error: true, data: error.response.data };
    }
  };


  const getActivityExportDataApi = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(
        `${ENDPOINT}${DASHBOARD_ACTIVITY_EXPORT_DATA}`,

        {
          responseType: "blob",
        }
      );
      setIsLoading(false);

      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      return { error: true, data: error.response.data };
    }
  };


  const getPtActivityExportDataApi = async (id) => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${ENDPOINT}${PT_ACTIVITY_EXPORT_DATA}?id=${id}`, {
        responseType: "blob",
      });
      setIsLoading(false);

      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      return { error: true, data: error.response.data };
    }
  };

  const getCompanyActivityExportDataApi = async (id) => {
    try {
      setIsLoading(true);
      const res = await axios.get(
        `${ENDPOINT}${COMPANY_ACTIVITY_EXPORT_DATA}?id=${id}`,
        {
          responseType: "blob",
        }
      );
      setIsLoading(false);

      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      return { error: true, data: error.response.data };
    }
  };

  const getUserActivityExportDataApi = async (id) => {
    try {
      setIsLoading(true);
      const res = await axios.get(
        `${ENDPOINT}${GET_USER_ACTIVITY_EXPORT_DATA}?id=${id}`,
        {
          responseType: "blob",
        }
      );
      setIsLoading(false);

      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      return { error: true, data: error.response.data };
    }
  };
  const sendMultiEmailApi = async (data,) => {
    try {
      setIsLoading(true);
      const res = await axios.post(
        `${ENDPOINT}${SEND_MULTI_EMAIL}`, // Appending courseId to the URL
        { ...data, cc: userData.email },
        {}
      );
      setIsLoading(false);
      openInfoSnackbar(res.data.message);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      openErrorSnackbar(error.response.data.message);
      return { error: true, data: error.response.data };
    }
  };

  const selectTagsCompanyApi = async (tags, companyIds) => {
    try {
      setIsLoading(true);
      const res = await axios.post(
        `${ENDPOINT}${SELECT_TAG_ON_COMPANY}`, // Appending courseId to the URL
        { companyIds, tags });
      setIsLoading(false);
      openInfoSnackbar(res.data.message);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      openErrorSnackbar(error.response.data.message);
      return { error: true, data: error.response.data };
    }
  };

  const selectTagsPhysicalTherapistApi = async (tags, therapistId) => {
    try {
      setIsLoading(true);
      const res = await axios.post(
        `${ENDPOINT}${SELECT_TAG_ON_PHYSICAL_THERAPIST}`, // Appending courseId to the URL
        { therapistId, tags });

      setIsLoading(false);
      openInfoSnackbar(res.data.message);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      openErrorSnackbar(error.response.data.message);
      return { error: true, data: error.response.data };
    }
  };


  const selectTagsEmployeesApi = async (tags, employeeId) => {
    try {
      setIsLoading(true);
      const res = await axios.post(
        `${ENDPOINT}${SELECT_TAG_ON_EMPLOYEES}`, // Appending courseId to the URL
        { employeeId, tags });

      setIsLoading(false);
      openInfoSnackbar(res.data.message);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      openErrorSnackbar(error.response.data.message);
      return { error: true, data: error.response.data };
    }
  };



  const selectTagsPatientApi = async (tags, patientId) => {
    try {
      setIsLoading(true);
      const res = await axios.post(
        `${ENDPOINT}${SELECT_TAG_ON_PATIENT}`, // Appending courseId to the URL
        { patientId, tags });

      setIsLoading(false);
      openInfoSnackbar(res.data.message);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      openErrorSnackbar(error.response.data.message);
      return { error: true, data: error.response.data };
    }
  };

  const searchApi = async (text) => {
    try {
      const res = await axios.get(
        `${ENDPOINT}${SEARCH}?search=${text}`, // Appending courseId to the URL
      );
      setIsLoading(false);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      openErrorSnackbar(error.response.data.message);
      return { error: true, data: error.response.data };
    }
  };


  const createAppointmentUrlApi = async (data,) => {
    try {
      setIsLoading(true);
      const res = await axios.post(
        `${ENDPOINT}${CREATE_APPOINTMENT}`, // Appending courseId to the URL
        { ...data },
        {}
      );
      setIsLoading(false);
      openInfoSnackbar(res.data.message);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      openErrorSnackbar(error.response.data.message);
      return { error: true, data: error.response.data };
    }
  };




  const getAllAppointmentLeadApi = async (page, limit,) => {
    try {
      setIsLoading(true);
      const res = await axios.get(
        `${ENDPOINT}${GET_APPOINTMENT_LEAD}?page=${page}&limit=${limit}`
      );
      setIsLoading(false);

      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      return { error: true, data: error.response.data };
    }
  };

  const getImageApi = async (key) => {
    try {
      const res = await axios.get(`${ENDPOINT}/image/${key}`)

      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      return { error: true, data: error.response.data };
    }
  }

  const getEmailExportDataApi = async (type) => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${ENDPOINT}${EMAIL_EXPORT_DATA}?type=${type}`, {
        responseType: "blob",
      });
      setIsLoading(false);

      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      return { error: true, data: error.response.data };
    }
  };


  const selectTagsEmailApi = async (tags, emailIds) => {
    try {
      setIsLoading(true);
      const res = await axios.post(
        `${ENDPOINT}${SELECT_TAG_ON_EMAIL}`, // Appending courseId to the URL
        { emailIds, tags }

      );
      setIsLoading(false);
      openInfoSnackbar(res.data.message);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      openErrorSnackbar(error.response.data.message);
      return { error: true, data: error.response.data };
    }
  };


  const getCompanyExportDataApi = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${ENDPOINT}${COMPANY_EXPORT_DATA}`, {
        responseType: "blob",
      });
      setIsLoading(false);

      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      return { error: true, data: error.response.data };
    }
  };

  const getPTExportDataApi = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${ENDPOINT}${PT_EXPORT_DATA}`, {
        responseType: "blob",
      });
      setIsLoading(false);

      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      return { error: true, data: error.response.data };
    }
  };

  const getEmployeeExportDataApi = async (id) => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${ENDPOINT}${EMPLOYEE_EXPORT_DATA}?id=${id}`, {
        responseType: "blob",
      });
      setIsLoading(false);

      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      return { error: true, data: error.response.data };
    }
  };

  const getPatientExportDataApi = async (id) => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${ENDPOINT}${PATIENT_EXPORT_DATA}?id=${id}`, {
        responseType: "blob",
      });
      setIsLoading(false);

      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      return { error: true, data: error.response.data };
    }
  };

  const getCompanyScaleDataApi = async (id) => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${ENDPOINT}${COMPANY_SCALE_DATA}${id}`,);
      setIsLoading(false);

      return { error: false, data: res.data.data };
    } catch (error) {
      setIsLoading(false);
      return { error: true, data: error.response.data };
    }
  };
  const getPhysicalTherapistScaleDataApi = async (id) => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${ENDPOINT}${PT_SCALE_DATA}${id}`,);
      setIsLoading(false);

      return { error: false, data: res.data.data };
    } catch (error) {
      setIsLoading(false);
      return { error: true, data: error.response.data };
    }
  };

  const getPtPatientExportDataApi = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${ENDPOINT}${PT_PATIENT_EXPORT_DATA}`, {
        responseType: "blob",
      });
      setIsLoading(false);

      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      return { error: true, data: error.response.data };
    }
  };


  const getCompanyPainCountExportDataApi = async (id) => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${ENDPOINT}${COMPANY_PAIN_COUNT_EXPORT_DATA}?id=${id}`, {
        responseType: "blob",
      });
      setIsLoading(false);

      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      return { error: true, data: error.response.data };
    }
  };


  const getCompanyActivityCountExportDataApi = async (id) => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${ENDPOINT}${COMPANY_ACTIVITY_COUNT_EXPORT_DATA}?id=${id}`, {
        responseType: "blob",
      });
      setIsLoading(false);

      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      return { error: true, data: error.response.data };
    }
  };

  const getPTPainCountExportDataApi = async (id) => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${ENDPOINT}${PT_PAIN_COUNT_EXPORT_DATA}?id=${id}`, {
        responseType: "blob",
      });
      setIsLoading(false);

      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      return { error: true, data: error.response.data };
    }
  };


  const getPTActivityCountExportDataApi = async (id) => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${ENDPOINT}${PT_ACTIVITY_COUNT_EXPORT_DATA}?id=${id}`, {
        responseType: "blob",
      });
      setIsLoading(false);

      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      return { error: true, data: error.response.data };
    }
  };

  const handleSubscriptionCancel = async (_id) => {
    try {
      setIsLoading(true);
      const res = await axios.post(`${ENDPOINT}${CANCEL_SUBSCRIPTION}?id=${_id}`, {
        subscriptionId: _id,
      });
      openInfoSnackbar(res.data.message);
      setIsLoading(false);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      openErrorSnackbar(error.response?.data?.message || 'Failed to cancel subscription');
      return { error: true, data: error.response?.data };
    }
  };

  const handleRenewSubscription = async (priceId) => {
    try {
      setIsLoading(true);
      const res = await axios.post(`${ENDPOINT}${RENEW_SUBSCRIPTION}`, {
        priceId: priceId,
      });
      openInfoSnackbar(res.data.message);
      setIsLoading(false);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      openErrorSnackbar(error.response?.data?.message || 'Failed to cancel subscription');
      return { error: true, data: error.response?.data };
    }
  };

  const sendEmailToResubscribeApiForCompanyApi = async (id) => {
    try {
      setIsLoading(true);
      const res = await axios.post(`${ENDPOINT}${SEND_EMAIL_TO_RESUBSCRIBE_API_FOR_COMPANY}`, {
        id: id
      });
      openInfoSnackbar(res.data.message);
      setIsLoading(false);
      return { error: false, data: res.data };
    } catch (error) {
      setIsLoading(false);
      openErrorSnackbar(error.response?.data?.message || 'Failed to cancel subscription');
      return { error: true, data: error.response?.data };
    }
  };


  return {
    homeApi,
    loginApi,
    logOutApi,
    createCourseApi,
    getAllCourse,
    getContactData,
    handleCourseDelete,
    createProductApi,
    deleteContactApi,
    replyContactApi,
    getCourseApi,
    updateCourseIconApi,
    updateCourseApi,
    getAllVideo,
    handleVideoDelete,
    getCourseVideoApi,
    updateCourseViewApi,
    updateCourseVideoApi,
    updateCourseVideoThumbnailApi,
    updateCourseVideoFileApi,
    updateCourseVideoViewApi,
    createVideoModuleApi,
    getAllVideoModule,
    updateCourseVideoModuleViewApi,
    handleVideoModuleDelete,
    updateVideoModuleApi,
    createCompany,

    forgetPasswordApi,
    changePasswordOTP,
    getAllCompany,
    createPhysicaltherapistApi,
    getAllPhysicaltherapist,
    deletePhysicaltherapistApi,
    getPhysicalTherapistApi,
    updatePhysicalTherapistApi,
    handleCompanyDelete,
    getCompany,
    createTagsApi,
    updateCompanyApi,
    createEmployeeApi,
    getEmployeeByCompanyId,
    getTagsApi,
    updateTagsApi,
    deleteTagsApi,
    getAllTagsApi,
    createPatientApi,
    getAllPhysicaltherapistPatient,
    deletePhysicaltherapistPatientApi,
    updatePhysicalTherapistPatient,
    handleDeleteEmployeeApi,
    getEmployeeById,
    updateEmployeeApi,
   
    getEmail,
    createOfferApi,
    getOfferApi,
    getOfferById,
    handleDeleteOfferApi,
    registerApi,
    createSubscription,
    updateSubscription,
    getAllPatient,
    updateOfferApi,
    updatePhysicalTherapistProfileData,
    updatePhysicalTherapistProfileImage,
    getAllPaymentsApi,
    getAllPainHab,
    getQuestionByHabIdApi,
    getQuestionVideoModuleApi,
    getQuestionVideoApi,
    createQuestionApi,
    getQuestionByIdApi,
    updateQuestionApi,
    deleteOptionActionApi,
    addOptionActionApi,
    getDashboardDetail,
    createEmailTemplateApi,
    getAllEmailTemplateApi,
    updateEmailTemplateApi,
    deleteEmailTemplateApi,
    getAllEmailListApi,
    createEmailAutoApi,
    getAllEmailAutoApi,
    deleteEmailAutoApi,
    getPatientById,
    changePasswordApi,
    getTransactionGraphDataApi,
    getPainGraphDataApi,
    getCompanyGraphApi,
    getPTGraphApi,
    getWebsiteContent,
    updateWebsiteContent,
    updateWebsiteContentImage,
    updateWebsiteContentVideo,
    getSatisfactionGraphDataApi,
    getCompanySatisfactionGraphApi,
    getPtSatisfactionGraphApi,
    getTransactionExportDataApi,
    getPainExportDataApi,
    getSatisfactionExportDataApi,
    getPtPainExportDataApi,
    getPtSatisfactionExportDataApi,
    getUserPainExportDataApi,
    getUserSatisfactionExportDataApi,
    getCompanyPainExportDataApi,
    getCompanySatisfactionExportDataApi,
    editOptionActionApi,
    getActivityGraphDataApi,
    getPtActivityGraphApi,
    getCompanyActivityGraphApi,
    getActivityExportDataApi,
    getPtActivityExportDataApi,
    getCompanyActivityExportDataApi,
    getUserActivityExportDataApi,
    sendMultiEmailApi,
    selectTagsCompanyApi,
    searchApi,
    createAppointmentUrlApi,
    getAllAppointmentLeadApi, getImageApi,
    selectTagsPhysicalTherapistApi,
    selectTagsEmployeesApi,
    selectTagsPatientApi,
    getEmailExportDataApi,
    deleteQuestionApi,
    selectTagsEmailApi,
    getPTExportDataApi,
    getCompanyExportDataApi,
    getEmployeeExportDataApi,
    getPatientExportDataApi,
    getCompanyScaleDataApi,
    getPhysicalTherapistScaleDataApi,
    getPtPatientExportDataApi,
    getCompanyPainCountExportDataApi,
    getCompanyActivityCountExportDataApi,
    getPTPainCountExportDataApi,
    getPTActivityCountExportDataApi,
    handleSubscriptionCancel,
    handleRenewSubscription,
    sendEmailToResubscribeApiForCompanyApi
  };
};



export default useApiAxios;
