import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import useApiAxios from "../../api/useApiAxios";
import { Link, useParams } from "react-router-dom";
import Select from "react-select";

const EditPhysicalTherapist = () => {
  const { getPhysicalTherapistApi, updatePhysicalTherapistApi, getAllTagsApi } =
    useApiAxios();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false); // State to track loading
  const [error, setError] = useState(null); // State to track errors
  const [tags, setTags] = useState(null);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedTagsSelected, setSelectedTagsSelected] = useState(null);
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      address: "",
      city: "",
      state: "",
      licenseNumber: "",

      zip: "",
      clinicName: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
      address: Yup.string().required("Address is required"),
      city: Yup.string().required("City is required"),
      state: Yup.string().required("State is required"),
      zip: Yup.string().required("Zip is required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required")
        .max(255, "Email must be at most 25 characters"),
      phone: Yup.string()
        .matches(/^\d{10}$/, {
          message: "Invalid Phone Number",
          excludeEmptyString: true,
        })
        .required("Phone number is required"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      // onSubmit logic remains the same
      await updatePhysicalTherapistApi(id, { ...values, tags: selectedTags });
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getAllTagsApi();
        const dataArray = [];
        if (!response.error) {
          for (let index = 0; index < response.data.data.length; index++) {
            const element = response.data.data[index];
            dataArray.push({ value: element._id, label: element.name });
          }
        }
        setTags(dataArray);
        const { error, data } = await getPhysicalTherapistApi(id);

        if (!error) {
          formik.setValues({
            name: data.data.name,

            email: data.data.email,
            phone: data.data.phone,
            address: data.data.address,
            city: data.data.city,
            state: data.data.state,
            licenseNumber: data.data.licenseNumber,
            zip: data.data.zip,
            clinicName: data.data.clinicName,
          });
        } else {
          setError("Failed to fetch data");
        }
      
        setSelectedTags(data.data.tags);
        const dataToSet = [];
        for (let index = 0; index < data.data.tags.length; index++) {
          const element = data.data.tags[index];
          for (let index2 = 0; index2 < dataArray.length; index2++) {
            const element2 = dataArray[index2];
            if (element === element2.value) {
              dataToSet.push(element2);
            }
          }
        }
        setSelectedTagsSelected(dataToSet);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        setError(error.message);
      }
    };
    fetchData();
  }, []);

  return (
    <div>
      <div className="container-fluid">
        <div className="row d-flex">
          <div className="col-sm-5">
            <nav aria-label="breadcrumb" className="theme-breadcrumb">
              <ol className="breadcrumb mb-0 ps-0 pt-1 pb-0">
                <li className="breadcrumb-item">
                  <Link to="/physical-therapists">PhysicalTherapists </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Update PhysicalTherapist
                </li>
              </ol>
            </nav>
            <h1 className="page-title">Update PhysicalTherapist</h1>
          </div>
        </div>
        <div className="row align-items-stretch">
          <div className="col-xxl-12 mb-3 order-1 order-xxl-1">
            <div className="card customer-sf h-100">
              <div className="p-30">
                <form onSubmit={formik.handleSubmit}>
                  <div className="row mt-4">
                    <div className="col-md-6 col-xl-6">
                      <div className="row mb-3 form-fieldc">
                        <label className="col-sm-4 col-form-label">Name</label>
                        <div className="col-sm-8">
                          <input
                            type="text"
                            className="form-control"
                            id="name"
                            {...formik.getFieldProps("name")}
                          />
                          {formik.errors.name ? (
                            <div className="error">{formik.errors.name}</div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row ">
                    <div className="col-md-6 col-xl-6">
                      <div className="row mb-3 form-fieldc">
                        <label className="col-sm-4 col-form-label">Email</label>
                        <div className="col-sm-8">
                          <input
                            type="email"
                            className="form-control"
                            id="email"
                            {...formik.getFieldProps("email")}
                          />
                          {formik.errors.email ? (
                            <div className="error">{formik.errors.email}</div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row ">
                    <div className="col-md-6 col-xl-6">
                      <div className="row mb-3 form-fieldc">
                        <label className="col-sm-4 col-form-label">Phone</label>
                        <div className="col-sm-8">
                          <input
                            type="tel"
                            className="form-control"
                            id="phone"
                            {...formik.getFieldProps("phone")}
                          />
                          {formik.errors.phone ? (
                            <div className="error">{formik.errors.phone}</div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-xl-6">
                      <div className="row mb-3 form-fieldc">
                        <label className="col-sm-4 col-form-label">
                          Address
                        </label>
                        <div className="col-sm-8">
                          <textarea
                            className="form-control address-field"
                            rows={3}
                            id="address"
                            {...formik.getFieldProps("address")}
                          />
                          {formik.errors.address ? (
                            <div className="error">{formik.errors.address}</div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-xl-6">
                      <div className="row mb-3 form-fieldc">
                        <label className="col-sm-4 col-form-label">City</label>
                        <div className="col-sm-8">
                          <textarea
                            className="form-control city-field"
                            rows={3}
                            id="city"
                            {...formik.getFieldProps("city")}
                          />
                          {formik.errors.city ? (
                            <div className="error">{formik.errors.city}</div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-xl-6">
                      <div className="row mb-3 form-fieldc">
                        <label className="col-sm-4 col-form-label">State</label>
                        <div className="col-sm-8">
                          <textarea
                            className="form-control state-field"
                            rows={3}
                            id="state"
                            {...formik.getFieldProps("state")}
                          />
                          {formik.errors.state ? (
                            <div className="error">{formik.errors.state}</div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row ">
                    <div className="col-md-6 col-xl-6">
                      <div className="row mb-3 form-fieldc">
                        <label className="col-sm-4 col-form-label">
                          License Number
                        </label>
                        <div className="col-sm-8">
                          <input
                            type="text"
                            className="form-control"
                            id="licenseNumber"
                            {...formik.getFieldProps("licenseNumber")}
                          />
                          {formik.errors.licenseNumber ? (
                            <div className="error">
                              {formik.errors.licenseNumber}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-xl-6">
                      <div className="row mb-3 form-fieldc">
                        <label className="col-sm-4 col-form-label">
                          Clinic Name
                        </label>
                        <div className="col-sm-8">
                          <input
                            type="text"
                            className="form-control"
                            id="clinicName"
                            {...formik.getFieldProps("clinicName")}
                          />
                          {formik.errors.clinicName ? (
                            <div className="error">
                              {formik.errors.clinicName}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row ">
                    <div className="col-md-6 col-xl-6">
                      <div className="row mb-3 form-fieldc">
                        <label className="col-sm-4 col-form-label">Zip</label>
                        <div className="col-sm-8">
                          <input
                            type="number"
                            className="form-control"
                            id="zip"
                            {...formik.getFieldProps("zip")}
                            min="1"
                          />
                          {formik.errors.zip ? (
                            <div className="error">{formik.errors.zip}</div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row ">
                    <div className="col-md-6 col-xl-6">
                      <div className="row mb-3 form-fieldc">
                        <label className="col-sm-4 col-form-label">Tags</label>
                        <div className="col-sm-8">
                          {selectedTagsSelected ? (
                            <Select
                              isSearchable={false}
                              isClearable={true}
                              isMulti
                              name="tags"
                              className="form-control"
                              defaultValue={
                                selectedTagsSelected ? selectedTagsSelected : []
                              }
                              onChange={(e) => {
                                setSelectedTagsSelected(e);

                                const dataToSet = [];
                                for (let index = 0; index < e.length; index++) {
                                  const element = e[index];
                                  dataToSet.push(element.value);
                                }
                                setSelectedTags(dataToSet);
                              }}
                              options={tags ? tags : []}
                            />
                          ) : (
                            <></>
                          )}
                          {formik.errors.experience ? (
                            <div className="error">
                              {formik.errors.experience}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div className="col-md-6 col-lg-12 col-xl-6">
                      <div className="row mb-3 form-fieldc">
                        <label className="col-sm-4 col-form-label" />
                        <div className="col-sm-8">
                          <button
                            type="submit"
                            className="btn addsubmit-btn green-btn  me-3 mb-3"
                            disabled={formik.isSubmitting}
                          >
                            Update
                          </button>
                          <Link
                            to={"/physical-therapists"}
                            type="submit"
                            className="btn addsubmit-btn grey-btn  mb-3"
                          >
                            Cancel
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditPhysicalTherapist;
