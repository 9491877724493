import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Context } from "../../context/Context";
import useApiAxios from "../../api/useApiAxios";
import { CopyToClipboard } from "react-copy-to-clipboard";
import DeleteModel from "../deleteModel/DeleteModel";

const Offers = () => {
  const [offers, setOffers] = useState([]);
  const { getOfferApi, handleDeleteOfferApi } = useApiAxios();
  const { userData, openInfoSnackbar } = useContext(Context);
  const [sort, setSort] = useState("");
  const [search, setSearch] = useState("");
  const [page, setPage] = useState("");
 

  const searchOffersHandler = async (e) => {
    setSearch(e.target.value);
  };

  const fun = async () => {
    const data = await getOfferApi(page, sort, search);
    setOffers(data.data.data);
  };

  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteData, setDeleteData] = useState(null);
  const openDeleteModel = (data) => {
    setDeleteData(data);
    setDeleteModal(true);
  };
  const closeDeleteModel = () => {
    setDeleteModal(false);
    setDeleteData(null);
  };
  const deleteOfferHandler = async (data) => {
    await handleDeleteOfferApi(data);
    await fun();
    closeDeleteModel();
  };

  useEffect(() => {
    fun();
  }, [page, sort, search]);

  const handleSort = async (selectedSort) => {
    setSort(selectedSort);
    setPage(1);
  };
  return (
    <div>
      <div className="row d-flex ">
        <div className="col-sm-5">
          <nav aria-label="breadcrumb" className="theme-breadcrumb">
            <ol className="breadcrumb mb-0 ps-0 pt-1 pb-0">
              <li className="breadcrumb-item">
                <Link to="/sales/offers">Offer</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Offer
              </li>
            </ol>
          </nav>
          <h1 className="page-title">Offer</h1>
        </div>
        <div className="col-sm-7 mb-3 mb-sm-0 d-flex justify-content-start justify-content-sm-end align-items-center">
          <div className="col-sm-7 mb-3 mb-sm-0 d-flex justify-content-start justify-content-sm-end align-items-center">
            &nbsp;
            <Link className="btn company-btn" to={"add"}>
              <i className="icon-19" /> Add Offer
            </Link>
          </div>
        </div>
      </div>
      <div className="row align-items-stretch">
        {/* Companies */}
        <div className="col-xxl-12 mb-3 order-1 order-xxl-1">
          <div className="card customer-sf h-100">
            <div className="p-3 pb-0">
              <div className="input-group search-table">
                <input
                  type="text"
                  className="form-control form-control-filter"
                  placeholder="Search Offer"
                  onChange={searchOffersHandler}
                />
                <i className="icon-13 search-ic" />
                <div className="btn-group bulk-action ">
                  <button
                    type="button"
                    className="filter-btn px-3 px-md-4"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    data-bs-auto-close="outside"
                  >
                    <i className="fa fa-filter me-2" /> Filters
                  </button>
                  <ul className="dropdown-menu dropdown-menu-end drop-tow mt-3">
                    <li>
                      <button
                        className="dropdown-item active"
                        type="button"
                        onClick={() => handleSort("newest")}
                      >
                        Newest
                      </button>
                    </li>
                    <li>
                      <button
                        className="dropdown-item"
                        type="button"
                        onClick={() => handleSort("oldest")}
                      >
                        Oldest
                      </button>
                    </li>
                    <li>
                      <button
                        className="dropdown-item"
                        type="button"
                        onClick={() => handleSort("updated")}
                      >
                        Recently Updated
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="pb-4 companies-table">
              <div className="mt-2">
                {offers && offers.some((item) => item.selected) ? (
                  <div className="select-comp">
                    <div className="btn-group bulk-action">
                      <button
                        type="button"
                        className="dropdown-toggle"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Bulk Actions
                      </button>
                      <ul
                        className="dropdown-menu dropdown-menu-end drop-tow mt-3"
                        style={{}}
                      >
                        <li>
                          <button
                            className="dropdown-item active"
                            type="button"
                          >
                            Approved
                          </button>
                        </li>
                        <li>
                          <button className="dropdown-item" type="button">
                            Disabled
                          </button>
                        </li>
                        <li>
                          <button className="dropdown-item" type="button">
                            Delete
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                ) : null}
              </div>

              <div className="mt-2 table-responsive">
                <table
                  id="example"
                  className="table display table-edits"
                  cellSpacing={0}
                  width="100%"
                >
                  <thead>
                    <tr>
                      <th className="text-center">S.No</th>
                      <th>Title</th>
                      <th>Created For</th>
                      <th>Created At</th>
                      <th>Three Month Price</th>
                      <th className="text-nowrap">Six Month Price</th>
                      <th className="text-nowrap">Year Price</th>
                      {userData.role === "admin" ? (
                        <th className="text-nowrap">Created By</th>
                      ) : (
                        ""
                      )}
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {offers?.map((item, index) => (
                      <tr key={index}>
                        <td className="text-center">{index + 1}</td>
                        <td>{item?.title}</td>
                        <td>{item?.createdFor}</td>
                        <td>{moment(item?.createdAt).format("MM/DD/YYYY")}</td>
                        <td>$ {item?.threeMonth?.price}</td>
                        <td>$ {item?.sixMonth?.price}</td>
                        <td>$ {item?.year?.price}</td>
                        {userData?.role === "admin" ? (
                          <td className="text-nowrap">
                            {item?.created_by?.name}
                          </td>
                        ) : (
                          ""
                        )}
                        <td className="text-nowrap">
                          <Link
                            to={`${process.env.REACT_APP_WEB_URL}/offer/${item?._id}/draft`}
                            className="action-btn"
                          >
                            <i className="fa-solid fa-eye"></i>
                          </Link>

                          <Link
                            to={`/sales/offers/edit/${item?._id}`}
                            className="action-btn"
                          >
                            <i className="fa-solid fa-pencil" />
                          </Link>

                          <Link
                            href="#!"
                            className="action-btn"
                            onClick={() => openDeleteModel(item._id)}
                          >
                            <i className="fa-solid fa-trash" />
                          </Link>
                          <CopyToClipboard
                            onCopy={() =>
                              openInfoSnackbar("Url copied to clipboard")
                            }
                            text={`${process.env.REACT_APP_WEB_URL}/offer/${item?._id}`}
                          >
                            <Link className="action-btn">
                              <i class="fa-solid fa-copy" />
                            </Link>
                          </CopyToClipboard>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <DeleteModel
          show={deleteModal}
          handleClose={closeDeleteModel}
          data={deleteData}
          deleteFunction={deleteOfferHandler}
        />
      </div>
    </div>
  );
};

export default Offers;
