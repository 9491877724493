import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import useApiAxios from "../../api/useApiAxios";
import AddPhysicalTherapistPatient from "./AddPhysicalTherapistPatient";
import EditPhysicalTherapistPatient from "./EditPhysicalTherapistPatient";
import moment from "moment";
import { Context } from "../../context/Context";
import EmployeeListModal from "../user/EmployeeListModal";
import PaginationComponent from "../../pagination/PaginationComponent";
import userDemo from "./../../assets/images/user-demo.svg";
import AddTagModel from "../addTagModel/AddTagModel";

const PhysicalTherapistPatientPT = () => {
  const limit = 4;
  const { id } = useParams();
  const [tagModel, setTagModel] = useState(false);

  const {
    getAllPatient,
    deletePhysicaltherapistPatientApi,
    getAllTagsApi,
    selectTagsPatientApi,
    getPtPatientExportDataApi,
  } = useApiAxios();
  const [physicalTherapistsPatient, setPhysicalTherapistsPatient] = useState(
    []
  );
  const closeTagModel = () => {
    setTagModel(false);
  };

  const [show, setShow] = useState(false);
  const [currentTag, setCurrentTag] = useState(null);
  const [employeeDataArray, setEmployeeDataArray] = useState([]);
  const [openEmployeeListModal, setOpenEmployeeListModal] = useState(false);
  const [total, setTotal] = useState(null);
  const [page, setPage] = useState(1);
  const [selectedAll, setSelectedAll] = useState(false);
  const { setShowEmailData, setShowEmail } = useContext(Context);
  const [currentPatient, setCurrentPatient] = useState({});
  const [showPatientEditModal, setShowPatientEditModal] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [sort, setSort] = useState("");
  const [search, setSearch] = useState("");
  const [searchField, setSearchField] = useState("");
  const [tags, setTags] = useState([]);

  const [count, setCount] = useState(0);
  const searchCourseHandler = async (e) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    let c = 0;
    physicalTherapistsPatient.forEach((item) => (item.selected ? (c += 1) : c));
    setCount(c);
  }, [physicalTherapistsPatient]);
  const openEmailModal = () => {
    setShowEmail(true);
    setShowEmailData(physicalTherapistsPatient);
  };
  function handleIncrementClick(_id, checked, all) {
    if (!all) {
      setSelectedAll(false);
      const nextCounters = physicalTherapistsPatient.map((item) => {
        if (item._id === _id) {
          // Increment the clicked counter
          return { ...item, selected: checked };
        } else {
          // The rest haven't changed
          return item;
        }
      });
      setPhysicalTherapistsPatient(nextCounters);
    } else {
      if (!selectedAll) {
        setSelectedAll(true);
        setPhysicalTherapistsPatient(
          physicalTherapistsPatient.map((item) => {
            return { ...item, selected: checked };
          })
        );
      } else {
        setSelectedAll(false);
        setPhysicalTherapistsPatient(
          physicalTherapistsPatient.map((item) => {
            return { ...item, selected: false };
          })
        );
      }
    }
  }

  const fun = async () => {
    const response = await getAllTagsApi();
    setTags(response.data.data);
    if (!searchField) {
      try {
        const data = await getAllPatient(id, sort, search, currentTag?._id);
      
        setPhysicalTherapistsPatient(data.data.data.patients ?? []);
        setTotal(data.data.total);
      } catch (error) {}
    } else {
      setPhysicalTherapistsPatient([]);
      setTotal(0);
    }
  };

  useEffect(() => {
    fun();
    document.title = "Patient";
  }, [id, searchField, sort, search, currentTag?._id]);

  const handleCheckboxChange = (_id, checked) => {
    if (checked) {
      setSelectedIds((prevSelectedIds) => [...prevSelectedIds, _id]); // Add the ID to selectedIds
    } else {
      setSelectedIds((prevSelectedIds) =>
        prevSelectedIds.filter((id) => id !== _id)
      ); // Remove the ID from selectedIds
    }
  };

  const handleDelete = async (physicalTherapistsPatientDataId) => {
    await deletePhysicaltherapistPatientApi(physicalTherapistsPatientDataId);
    fun();
  };
  const handleEditPatient = async (patient) => {
    setCurrentPatient(patient);
    setShowPatientEditModal(true);
  };

  const handleSort = async (selectedSort) => {
    setSort(selectedSort);
    setPage(1);
  };
  const selectTagFunction = async (tagId) => {
    const patientId = [];
    for (let index = 0; index < physicalTherapistsPatient.length; index++) {
      const element = physicalTherapistsPatient[index];
      if (element.selected) {
        patientId.push(element._id);
      }
    }
    const response = await selectTagsPatientApi(tagId, patientId);
  };

  const handlePTPatientExportData = async () => {
    try {
      const response = await getPtPatientExportDataApi();
      const url = window.URL.createObjectURL(
        new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "PTPatient.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error exporting data:", error);
    }
  };

  return (
    <>
      <div className="row d-flex ">
        <div className="col-9">
          <nav aria-label="breadcrumb" className="theme-breadcrumb">
            <ol className="breadcrumb mb-0 ps-0 pt-1 pb-0">
              <li className="breadcrumb-item">Patients</li>
              <li className="breadcrumb-item active" aria-current="page">
                Patients
              </li>
            </ol>
          </nav>
          <h1 className="page-title">
            Patients<span>({total})</span>
          </h1>
        </div>
        <div className="col-3 ps-0 text-end">
          <button
            onClick={handlePTPatientExportData}
            className="btn btn-sm btn-success"
          >
            Export
          </button>
        </div>
      </div>
      {/* Companies */}
      <div className="row align-items-stretch">
        {/* Companies */}
        <div className="col-xxl-12 mb-3 order-1 order-xxl-1">
          <div className="card customer-sf">
            <div className="p-3 pb-0">
              <div className="input-group search-table">
                <input
                  type="text"
                  className="form-control form-control-filter"
                  placeholder="Search Patient"
                  onChange={searchCourseHandler}
                />
                <i className="icon-13 search-ic" />
                <div className="btn-group bulk-action ms-2">
                  <button
                    type="button"
                    className="filter-btn px-3 px-md-4"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    data-bs-auto-close="outside"
                  >
                    <i className="fa fa-filter me-2" /> Filters
                  </button>
                  <ul className="dropdown-menu dropdown-menu-end drop-tow mt-3">
                    <li>
                      <button
                        className="dropdown-item"
                        type="button"
                        onClick={() => handleSort("newest")}
                      >
                        Newest
                      </button>
                    </li>
                    <li>
                      <button
                        className="dropdown-item"
                        type="button"
                        onClick={() => handleSort("oldest")}
                      >
                        Oldest
                      </button>
                    </li>
                    <li>
                      <button
                        className="dropdown-item"
                        type="button"
                        onClick={() => handleSort("updated")}
                      >
                        Recently Updated
                      </button>
                    </li>
                    <span>By Tag</span>
                    <li>
                      {tags.map((item) => (
                        <button
                          className={`dropdown-item ${
                            item?._id === currentTag?._id ? "active" : ""
                          }`}
                          type="button"
                          onClick={() => {
                            if (item?._id === currentTag?._id) {
                              setCurrentTag(null);
                            } else {
                              setCurrentTag(item);
                            }
                          }}
                        >
                          {item.name}
                        </button>
                      ))}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="companies-table">
              <div className="mt-2">
                {physicalTherapistsPatient &&
                physicalTherapistsPatient?.some((item) => item.selected) ? (
                  <div className="text-end">
                    <label className="col-form-label">Selected {count}</label>
                    <div className="btn-group bulk-action ms-2">
                      <button
                        type="button"
                        className="dropdown-toggle"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Bulk Actions
                      </button>
                      <ul
                        className="dropdown-menu dropdown-menu-end drop-tow mt-3"
                        style={{}}
                      >
                        <li>
                          <button
                            className="dropdown-item "
                            type="button"
                            onClick={openEmailModal}
                          >
                            Send Email
                          </button>
                        </li>
                        <li>
                          <button
                            className="dropdown-item "
                            type="button"
                            onClick={() => setTagModel(true)}
                          >
                            Add tag
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="pb-4 companies-table">
              <div className="mt-2 table-responsive">
                <table
                  id="example"
                  className="table display table-edits"
                  cellSpacing={0}
                  width="100%"
                >
                  <thead>
                    <tr>
                      <th
                        className="sorting"
                        tabIndex={0}
                        aria-controls="example"
                        rowSpan={1}
                        colSpan={1}
                        aria-label="Name: activate to sort column ascending"
                      >
                        <span className="nochecked">
                          <div className="form-check">
                            <input
                              className="form-check-input mr-1"
                              type="checkbox"
                              defaultValue=""
                              checked={selectedAll}
                              onClick={() =>
                                handleIncrementClick("", true, true)
                              }
                            />
                          </div>
                        </span>
                      </th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Phone</th>
                      <th>Date Of Birth</th>
                      <th>Gender</th>
                      <th>Plan</th>
                      <th>Plan Expiry</th>
                      <th
                        className="sorting"
                        tabIndex={0}
                        aria-controls="example"
                        rowSpan={1}
                        colSpan={1}
                        aria-label="PROGRESS: activate to sort column ascending"
                        style={{ width: 78 }}
                      >
                        Progress
                      </th>
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {/* Mapping employeeData */}
                    {physicalTherapistsPatient &&
                      physicalTherapistsPatient.map((patient, index) => (
                        <tr key={index}>
                          {/* Display employee details */}
                          <td className="checked">
                            <span className="form-check d-flex align-items-center ">
                              <input
                                className="form-check-input mr-1"
                                type="checkbox"
                                checked={patient?.selected}
                                onClick={(e) => {
                                  handleIncrementClick(
                                    patient._id,
                                    e.target.checked
                                  );
                                }}
                                onChange={(e) =>
                                  handleCheckboxChange(
                                    patient._id,
                                    e.target.checked
                                  )
                                }
                              />
                            </span>
                          </td>
                          <td>
                            <label
                              className="form-check-label text-truncate d-flex align-items-center"
                              htmlFor="remember4"
                            >
                              <span className="uusr_img me-2">
                                <img src={userDemo} alt="" />
                              </span>
                              {patient.name}
                            </label>
                          </td>
                          <td>{patient.email}</td>
                          <td>{patient.phone}</td>

                          <td>
                            {moment(patient.dateOfBirth).format("MM/DD/YYYY")}
                          </td>

                          <td>{patient.gender}</td>
                          <td>{patient?.offer?.title}</td>
                          <td>
                            {patient?.plainExpire
                              ? moment(patient.plainExpire).format("MM/DD/YYYY")
                              : "n/a"}
                          </td>
                          <td className="align-middle">
                            <div className="progress progress-bg1">
                              <div
                                className="progress-bar progress-red"
                                role="progressbar"
                                style={{ width: "0%" }}
                                aria-valuenow={0}
                                aria-valuemin={0}
                                aria-valuemax={100}
                              />
                            </div>
                          </td>
                          {/* Action buttons */}
                          <td className="text-nowrap text-center">
                            <Link
                              to={`/employee-details/${patient._id}`}
                              className="btn btn-sm view-btn"
                            >
                              <i className="fa fa-eye" /> VIEW
                            </Link>
                            <Link
                              to={`tel:${patient?.phone?.substring(
                                0,
                                3
                              )}-${patient?.phone?.substring(3)}`}
                              className="action-btn"
                            >
                              <i className="fa fa-phone" title="Phone"></i>
                            </Link>
                            <Link
                              onClick={() => {
                                setShowEmail(true);
                                setShowEmailData([
                                  {
                                    email: patient.email,
                                    name: patient.name,
                                    selected: true,
                                  },
                                ]);
                              }}
                              className="action-btn"
                            >
                              <i className="fa fa-envelope" title="Message"></i>
                            </Link>

                            <Link
                              to=""
                              className="action-btn"
                              onClick={() => {
                                handleEditPatient(patient);
                              }}
                            >
                              <i className="fa fa-pencil"></i>
                            </Link>
                            <Link
                              className="action-btn"
                              onClick={() => handleDelete(patient._id)}
                            >
                              <i className="fa fa-trash"></i>
                            </Link>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {/* /Companies */}
      </div>
      {/*/Row*/}
      {/* Companies */}
      <EmployeeListModal
        show={openEmployeeListModal}
        handleClose={() => {
          setOpenEmployeeListModal(false);
        }}
        onSubmit={async (data) => {
          setOpenEmployeeListModal(false);
          await fun();
        }}
        data={employeeDataArray}
        title={"Patient List"}
        buttonTitle="Create Patient"
      />
      <EditPhysicalTherapistPatient
        show={showPatientEditModal}
        handleClose={() => {
          setShowPatientEditModal(false);
          setCurrentPatient({});
        }}
        currentPatient={currentPatient}
        fun={fun}
      />

      <AddPhysicalTherapistPatient
        fun={fun}
        show={show}
        handleClose={() => setShow(false)}
      />
      <AddTagModel
        show={tagModel}
        handleClose={closeTagModel}
        data={tags}
        selectTagFunction={selectTagFunction}
      />
      <PaginationComponent
        total={total}
        page={page}
        limit={limit}
        setPage={setPage}
      />
    </>
  );
};

export default PhysicalTherapistPatientPT;
